import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _001fb762 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _67a9c583 = () => interopDefault(import('../pages/api-and-developers.vue' /* webpackChunkName: "pages/api-and-developers" */))
const _60a14dd1 = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _294f381f = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _478346f3 = () => interopDefault(import('../pages/contests/index.vue' /* webpackChunkName: "pages/contests/index" */))
const _d6e56c48 = () => interopDefault(import('../pages/delete-account.vue' /* webpackChunkName: "pages/delete-account" */))
const _b5570da2 = () => interopDefault(import('../pages/glossary.vue' /* webpackChunkName: "pages/glossary" */))
const _1fb1f499 = () => interopDefault(import('../pages/go-fly-a-kite.vue' /* webpackChunkName: "pages/go-fly-a-kite" */))
const _652415cd = () => interopDefault(import('../pages/grades.vue' /* webpackChunkName: "pages/grades" */))
const _c737c676 = () => interopDefault(import('../pages/gym-administrations/index.vue' /* webpackChunkName: "pages/gym-administrations/index" */))
const _41ad5273 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _0288733a = () => interopDefault(import('../pages/library.vue' /* webpackChunkName: "pages/library" */))
const _8b496bdc = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _2cabd502 = () => interopDefault(import('../pages/merci.vue' /* webpackChunkName: "pages/merci" */))
const _dcd6873c = () => interopDefault(import('../pages/new/index.vue' /* webpackChunkName: "pages/new/index" */))
const _c0e7c48e = () => interopDefault(import('../pages/new-password.vue' /* webpackChunkName: "pages/new-password" */))
const _19c363c8 = () => interopDefault(import('../pages/newsletters/index.vue' /* webpackChunkName: "pages/newsletters/index" */))
const _5881ee2c = () => interopDefault(import('../pages/notifications/index.vue' /* webpackChunkName: "pages/notifications/index" */))
const _7a5e53ec = () => interopDefault(import('../pages/oblyk.vue' /* webpackChunkName: "pages/oblyk" */))
const _ff0978f2 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _5df6c357 = () => interopDefault(import('../pages/report-problem.vue' /* webpackChunkName: "pages/report-problem" */))
const _3264f7ac = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _e83fb698 = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _bf1309ac = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _733ae23a = () => interopDefault(import('../pages/success-account-deleting.vue' /* webpackChunkName: "pages/success-account-deleting" */))
const _7fcb572d = () => interopDefault(import('../pages/support-us.vue' /* webpackChunkName: "pages/support-us" */))
const _0354a226 = () => interopDefault(import('../pages/about/indoor.vue' /* webpackChunkName: "pages/about/indoor" */))
const _66d49620 = () => interopDefault(import('../pages/about/partner-search.vue' /* webpackChunkName: "pages/about/partner-search" */))
const _6268d856 = () => interopDefault(import('../pages/areas/new.vue' /* webpackChunkName: "pages/areas/new" */))
const _20d0655f = () => interopDefault(import('../pages/articles/new.vue' /* webpackChunkName: "pages/articles/new" */))
const _07e4962e = () => interopDefault(import('../pages/ascents/new.vue' /* webpackChunkName: "pages/ascents/new" */))
const _b4d67de4 = () => interopDefault(import('../pages/crags/new.vue' /* webpackChunkName: "pages/crags/new" */))
const _c49ed0ac = () => interopDefault(import('../pages/crags/search.vue' /* webpackChunkName: "pages/crags/search" */))
const _6417ef46 = () => interopDefault(import('../pages/en/cookie-and-audience-measurement.vue' /* webpackChunkName: "pages/en/cookie-and-audience-measurement" */))
const _2394d5bc = () => interopDefault(import('../pages/en/terms-of-use.vue' /* webpackChunkName: "pages/en/terms-of-use" */))
const _33e1d513 = () => interopDefault(import('../pages/errors/right-required.vue' /* webpackChunkName: "pages/errors/right-required" */))
const _0bbff98a = () => interopDefault(import('../pages/escalade-en/france/index.vue' /* webpackChunkName: "pages/escalade-en/france/index" */))
const _22bc3534 = () => interopDefault(import('../pages/find/climbers.vue' /* webpackChunkName: "pages/find/climbers" */))
const _37f1a38e = () => interopDefault(import('../pages/find/crags.vue' /* webpackChunkName: "pages/find/crags" */))
const _79615658 = () => interopDefault(import('../pages/find/guide-books.vue' /* webpackChunkName: "pages/find/guide-books" */))
const _edf1e826 = () => interopDefault(import('../pages/find/gyms.vue' /* webpackChunkName: "pages/find/gyms" */))
const _72cd8a26 = () => interopDefault(import('../pages/fr/conditions-d-utilisation.vue' /* webpackChunkName: "pages/fr/conditions-d-utilisation" */))
const _11dfa7a6 = () => interopDefault(import('../pages/fr/cookie-et-mesure-d-audience.vue' /* webpackChunkName: "pages/fr/cookie-et-mesure-d-audience" */))
const _44407102 = () => interopDefault(import('../pages/guide-book-papers/find.vue' /* webpackChunkName: "pages/guide-book-papers/find" */))
const _7ea3baca = () => interopDefault(import('../pages/guide-book-papers/new.vue' /* webpackChunkName: "pages/guide-book-papers/new" */))
const _79daa06e = () => interopDefault(import('../pages/guide-book-papers/new-versions.vue' /* webpackChunkName: "pages/guide-book-papers/new-versions" */))
const _883d2ff6 = () => interopDefault(import('../pages/guide-book-papers/recommended.vue' /* webpackChunkName: "pages/guide-book-papers/recommended" */))
const _752e6c04 = () => interopDefault(import('../pages/guide-books/features.vue' /* webpackChunkName: "pages/guide-books/features" */))
const _4df492ba = () => interopDefault(import('../pages/gyms/new.vue' /* webpackChunkName: "pages/gyms/new" */))
const _53ffedae = () => interopDefault(import('../pages/gyms/starter.vue' /* webpackChunkName: "pages/gyms/starter" */))
const _701a1001 = () => interopDefault(import('../pages/home/climbing-sessions/index.vue' /* webpackChunkName: "pages/home/climbing-sessions/index" */))
const _0746c98a = () => interopDefault(import('../pages/home/community.vue' /* webpackChunkName: "pages/home/community" */))
const _234918d0 = () => interopDefault(import('../pages/home/community/followers.vue' /* webpackChunkName: "pages/home/community/followers" */))
const _02bdbb84 = () => interopDefault(import('../pages/home/community/subscribes.vue' /* webpackChunkName: "pages/home/community/subscribes" */))
const _190bc610 = () => interopDefault(import('../pages/home/favorites.vue' /* webpackChunkName: "pages/home/favorites" */))
const _08b4a632 = () => interopDefault(import('../pages/home/favorites/crags.vue' /* webpackChunkName: "pages/home/favorites/crags" */))
const _05320102 = () => interopDefault(import('../pages/home/favorites/gyms.vue' /* webpackChunkName: "pages/home/favorites/gyms" */))
const _b60b2a06 = () => interopDefault(import('../pages/home/guide-books/index.vue' /* webpackChunkName: "pages/home/guide-books/index" */))
const _1d387ef6 = () => interopDefault(import('../pages/home/media.vue' /* webpackChunkName: "pages/home/media" */))
const _4186c17b = () => interopDefault(import('../pages/home/media/photos.vue' /* webpackChunkName: "pages/home/media/photos" */))
const _014d1e5c = () => interopDefault(import('../pages/home/media/videos.vue' /* webpackChunkName: "pages/home/media/videos" */))
const _7a7aafb4 = () => interopDefault(import('../pages/home/messenger.vue' /* webpackChunkName: "pages/home/messenger" */))
const _002c4f76 = () => interopDefault(import('../pages/home/messenger/new.vue' /* webpackChunkName: "pages/home/messenger/new" */))
const _585ee8c5 = () => interopDefault(import('../pages/home/messenger/_conversationId/index.vue' /* webpackChunkName: "pages/home/messenger/_conversationId/index" */))
const _b77c1512 = () => interopDefault(import('../pages/home/search-climbers.vue' /* webpackChunkName: "pages/home/search-climbers" */))
const _1082e6dc = () => interopDefault(import('../pages/home/settings.vue' /* webpackChunkName: "pages/home/settings" */))
const _05686cb4 = () => interopDefault(import('../pages/home/settings/avatar.vue' /* webpackChunkName: "pages/home/settings/avatar" */))
const _5055340e = () => interopDefault(import('../pages/home/settings/banner.vue' /* webpackChunkName: "pages/home/settings/banner" */))
const _6d8f1a2b = () => interopDefault(import('../pages/home/settings/connection.vue' /* webpackChunkName: "pages/home/settings/connection" */))
const _68fcb82a = () => interopDefault(import('../pages/home/settings/general.vue' /* webpackChunkName: "pages/home/settings/general" */))
const _7ecef3ab = () => interopDefault(import('../pages/home/settings/notifications.vue' /* webpackChunkName: "pages/home/settings/notifications" */))
const _0b8256b0 = () => interopDefault(import('../pages/home/settings/others.vue' /* webpackChunkName: "pages/home/settings/others" */))
const _5d8ef06a = () => interopDefault(import('../pages/home/settings/partner.vue' /* webpackChunkName: "pages/home/settings/partner" */))
const _1e8922ea = () => interopDefault(import('../pages/home/settings/privacy.vue' /* webpackChunkName: "pages/home/settings/privacy" */))
const _066dad48 = () => interopDefault(import('../pages/maps/climbers.vue' /* webpackChunkName: "pages/maps/climbers" */))
const _65c94197 = () => interopDefault(import('../pages/maps/crags.vue' /* webpackChunkName: "pages/maps/crags" */))
const _3eda6253 = () => interopDefault(import('../pages/maps/guide-book-papers.vue' /* webpackChunkName: "pages/maps/guide-book-papers" */))
const _4b2614cf = () => interopDefault(import('../pages/maps/gyms.vue' /* webpackChunkName: "pages/maps/gyms" */))
const _16e2699c = () => interopDefault(import('../pages/maps/my-map.vue' /* webpackChunkName: "pages/maps/my-map" */))
const _0a96fc6a = () => interopDefault(import('../pages/newsletters/new.vue' /* webpackChunkName: "pages/newsletters/new" */))
const _4f9a44d8 = () => interopDefault(import('../pages/newsletters/subscribe.vue' /* webpackChunkName: "pages/newsletters/subscribe" */))
const _53f11afd = () => interopDefault(import('../pages/newsletters/successful-subscribe.vue' /* webpackChunkName: "pages/newsletters/successful-subscribe" */))
const _17f5b244 = () => interopDefault(import('../pages/newsletters/successful-unsubscribe.vue' /* webpackChunkName: "pages/newsletters/successful-unsubscribe" */))
const _da5da9ca = () => interopDefault(import('../pages/newsletters/unsubscribe.vue' /* webpackChunkName: "pages/newsletters/unsubscribe" */))
const _5f71ff70 = () => interopDefault(import('../pages/organizations/new.vue' /* webpackChunkName: "pages/organizations/new" */))
const _370fde79 = () => interopDefault(import('../pages/words/new.vue' /* webpackChunkName: "pages/words/new" */))
const _0cd48aaa = () => interopDefault(import('../pages/ascents/indoor/new.vue' /* webpackChunkName: "pages/ascents/indoor/new" */))
const _36cc70d6 = () => interopDefault(import('../pages/ascents/outdoor/new.vue' /* webpackChunkName: "pages/ascents/outdoor/new" */))
const _dc14620a = () => interopDefault(import('../pages/home/ascents/indoor/index.vue' /* webpackChunkName: "pages/home/ascents/indoor/index" */))
const _51509727 = () => interopDefault(import('../pages/home/ascents/outdoor.vue' /* webpackChunkName: "pages/home/ascents/outdoor" */))
const _0522e46a = () => interopDefault(import('../pages/home/ascents/outdoor/index.vue' /* webpackChunkName: "pages/home/ascents/outdoor/index" */))
const _23d8bbd4 = () => interopDefault(import('../pages/home/ascents/outdoor/analytiks.vue' /* webpackChunkName: "pages/home/ascents/outdoor/analytiks" */))
const _409407dc = () => interopDefault(import('../pages/home/ascents/outdoor/projects.vue' /* webpackChunkName: "pages/home/ascents/outdoor/projects" */))
const _7b2f5866 = () => interopDefault(import('../pages/home/ascents/outdoor/tick-list.vue' /* webpackChunkName: "pages/home/ascents/outdoor/tick-list" */))
const _5e75440c = () => interopDefault(import('../pages/home/guide-books/add.vue' /* webpackChunkName: "pages/home/guide-books/add" */))
const _5f28c191 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _33b50649 = () => interopDefault(import('../pages/home/climbing-sessions/_sessionDate/index.vue' /* webpackChunkName: "pages/home/climbing-sessions/_sessionDate/index" */))
const _ca860aea = () => interopDefault(import('../pages/escalade-en/france/_departmentNumber/_departementName/index.vue' /* webpackChunkName: "pages/escalade-en/france/_departmentNumber/_departementName/index" */))
const _a767b8b0 = () => interopDefault(import('../pages/climbers/_userName.vue' /* webpackChunkName: "pages/climbers/_userName" */))
const _ef6d67aa = () => interopDefault(import('../pages/climbers/_userName/index.vue' /* webpackChunkName: "pages/climbers/_userName/index" */))
const _e9526b1c = () => interopDefault(import('../pages/climbers/_userName/ascents.vue' /* webpackChunkName: "pages/climbers/_userName/ascents" */))
const _fac71080 = () => interopDefault(import('../pages/climbers/_userName/ascents/indoor.vue' /* webpackChunkName: "pages/climbers/_userName/ascents/indoor" */))
const _6f2c6a42 = () => interopDefault(import('../pages/climbers/_userName/ascents/outdoor.vue' /* webpackChunkName: "pages/climbers/_userName/ascents/outdoor" */))
const _409a3524 = () => interopDefault(import('../pages/climbers/_userName/followers.vue' /* webpackChunkName: "pages/climbers/_userName/followers" */))
const _37ffba3d = () => interopDefault(import('../pages/climbers/_userName/media.vue' /* webpackChunkName: "pages/climbers/_userName/media" */))
const _1508e8c3 = () => interopDefault(import('../pages/climbers/_userName/media/photos.vue' /* webpackChunkName: "pages/climbers/_userName/media/photos" */))
const _5a48cfcc = () => interopDefault(import('../pages/climbers/_userName/media/videos.vue' /* webpackChunkName: "pages/climbers/_userName/media/videos" */))
const _611e2d40 = () => interopDefault(import('../pages/climbers/_userName/subscribes.vue' /* webpackChunkName: "pages/climbers/_userName/subscribes" */))
const _56b88298 = () => interopDefault(import('../pages/comments/_commentId/index.vue' /* webpackChunkName: "pages/comments/_commentId/index" */))
const _45283b78 = () => interopDefault(import('../pages/escalade-autour-de/_townName/index.vue' /* webpackChunkName: "pages/escalade-autour-de/_townName/index" */))
const _5319856f = () => interopDefault(import('../pages/gr/_gymAndRouteId/index.vue' /* webpackChunkName: "pages/gr/_gymAndRouteId/index" */))
const _708e5a52 = () => interopDefault(import('../pages/grs/_gymId/index.vue' /* webpackChunkName: "pages/grs/_gymId/index" */))
const _b112f8f4 = () => interopDefault(import('../pages/gym-chains/_gymChainId.vue' /* webpackChunkName: "pages/gym-chains/_gymChainId" */))
const _4b97cd89 = () => interopDefault(import('../pages/gym-chains/_gymChainId/index.vue' /* webpackChunkName: "pages/gym-chains/_gymChainId/index" */))
const _7db930be = () => interopDefault(import('../pages/photos/_photoId/index.vue' /* webpackChunkName: "pages/photos/_photoId/index" */))
const _2e05b950 = () => interopDefault(import('../pages/videos/_videoId/index.vue' /* webpackChunkName: "pages/videos/_videoId/index" */))
const _331c4c0c = () => interopDefault(import('../pages/alerts/_alertId/edit.vue' /* webpackChunkName: "pages/alerts/_alertId/edit" */))
const _019cbdc5 = () => interopDefault(import('../pages/authors/_authorId/cover.vue' /* webpackChunkName: "pages/authors/_authorId/cover" */))
const _50eb2c28 = () => interopDefault(import('../pages/authors/_authorId/edit.vue' /* webpackChunkName: "pages/authors/_authorId/edit" */))
const _82c62498 = () => interopDefault(import('../pages/comments/_commentId/edit.vue' /* webpackChunkName: "pages/comments/_commentId/edit" */))
const _572346fe = () => interopDefault(import('../pages/guide-book-pdfs/_guideBookPdfId/edit.vue' /* webpackChunkName: "pages/guide-book-pdfs/_guideBookPdfId/edit" */))
const _fea78184 = () => interopDefault(import('../pages/guide-book-webs/_guideBookWebId/edit.vue' /* webpackChunkName: "pages/guide-book-webs/_guideBookWebId/edit" */))
const _cb78e168 = () => interopDefault(import('../pages/links/_linkId/edit.vue' /* webpackChunkName: "pages/links/_linkId/edit" */))
const _a15b2736 = () => interopDefault(import('../pages/areas/_areaId/_areaName.vue' /* webpackChunkName: "pages/areas/_areaId/_areaName" */))
const _7d735328 = () => interopDefault(import('../pages/areas/_areaId/_areaName/index.vue' /* webpackChunkName: "pages/areas/_areaId/_areaName/index" */))
const _07cdf694 = () => interopDefault(import('../pages/areas/_areaId/_areaName/crags.vue' /* webpackChunkName: "pages/areas/_areaId/_areaName/crags" */))
const _2c4e0cca = () => interopDefault(import('../pages/areas/_areaId/_areaName/photos.vue' /* webpackChunkName: "pages/areas/_areaId/_areaName/photos" */))
const _bf576d3e = () => interopDefault(import('../pages/articles/_articleId/_articleName/index.vue' /* webpackChunkName: "pages/articles/_articleId/_articleName/index" */))
const _177830d8 = () => interopDefault(import('../pages/crag-routes/_cragRouteId/_cragRouteName/index.vue' /* webpackChunkName: "pages/crag-routes/_cragRouteId/_cragRouteName/index" */))
const _95afc33c = () => interopDefault(import('../pages/crag-sectors/_cragSectorId/_cragSectorName.vue' /* webpackChunkName: "pages/crag-sectors/_cragSectorId/_cragSectorName" */))
const _f3ee5936 = () => interopDefault(import('../pages/crag-sectors/_cragSectorId/_cragSectorName/index.vue' /* webpackChunkName: "pages/crag-sectors/_cragSectorId/_cragSectorName/index" */))
const _01035954 = () => interopDefault(import('../pages/crag-sectors/_cragSectorId/_cragSectorName/maps.vue' /* webpackChunkName: "pages/crag-sectors/_cragSectorId/_cragSectorName/maps" */))
const _1818fe04 = () => interopDefault(import('../pages/crag-sectors/_cragSectorId/_cragSectorName/photos.vue' /* webpackChunkName: "pages/crag-sectors/_cragSectorId/_cragSectorName/photos" */))
const _520c2bad = () => interopDefault(import('../pages/crags/_cragId/_cragName.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName" */))
const _18627c70 = () => interopDefault(import('../pages/crags/_cragId/_cragName/index.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/index" */))
const _52a44037 = () => interopDefault(import('../pages/crags/_cragId/_cragName/guide-books.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/guide-books" */))
const _2e825052 = () => interopDefault(import('../pages/crags/_cragId/_cragName/links.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/links" */))
const _407c52a8 = () => interopDefault(import('../pages/crags/_cragId/_cragName/maps/index.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/maps/index" */))
const _a6620d5a = () => interopDefault(import('../pages/crags/_cragId/_cragName/photos.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/photos" */))
const _8a7045c2 = () => interopDefault(import('../pages/crags/_cragId/_cragName/routes/index.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/routes/index" */))
const _4e759666 = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName" */))
const _b58fa4e0 = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/index.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/index" */))
const _0e0c1c18 = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/alternatives.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/alternatives" */))
const _2f92d0fc = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/crags.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/crags" */))
const _759488f7 = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/links.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/links" */))
const _40ade69a = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/map.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/map" */))
const _8aa1279a = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/photos.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/photos" */))
const _50a73f76 = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/points-of-sale.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/points-of-sale" */))
const _56969419 = () => interopDefault(import('../pages/gyms/_gymId/_gymName.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName" */))
const _32c03848 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/index" */))
const _7ca43c9f = () => interopDefault(import('../pages/gyms/_gymId/_gymName/followers.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/followers" */))
const _22da1314 = () => interopDefault(import('../pages/newsletters/_newsletterId/_newsletterName/index.vue' /* webpackChunkName: "pages/newsletters/_newsletterId/_newsletterName/index" */))
const _51e7194b = () => interopDefault(import('../pages/organizations/_organizationId/_organizationName.vue' /* webpackChunkName: "pages/organizations/_organizationId/_organizationName" */))
const _17b03b8e = () => interopDefault(import('../pages/organizations/_organizationId/_organizationName/index.vue' /* webpackChunkName: "pages/organizations/_organizationId/_organizationName/index" */))
const _30934226 = () => interopDefault(import('../pages/words/_wordId/_slugName/index.vue' /* webpackChunkName: "pages/words/_wordId/_slugName/index" */))
const _1634974c = () => interopDefault(import('../pages/alerts/_alertableType/_alertableId/new.vue' /* webpackChunkName: "pages/alerts/_alertableType/_alertableId/new" */))
const _2f8d5f21 = () => interopDefault(import('../pages/articles/_articleId/_articleName/add-crags.vue' /* webpackChunkName: "pages/articles/_articleId/_articleName/add-crags" */))
const _23d19088 = () => interopDefault(import('../pages/articles/_articleId/_articleName/add-guide-books.vue' /* webpackChunkName: "pages/articles/_articleId/_articleName/add-guide-books" */))
const _467679c6 = () => interopDefault(import('../pages/articles/_articleId/_articleName/cover.vue' /* webpackChunkName: "pages/articles/_articleId/_articleName/cover" */))
const _9f0eac6a = () => interopDefault(import('../pages/articles/_articleId/_articleName/edit.vue' /* webpackChunkName: "pages/articles/_articleId/_articleName/edit" */))
const _2316ca82 = () => interopDefault(import('../pages/articles/_articleId/_articleName/photos.vue' /* webpackChunkName: "pages/articles/_articleId/_articleName/photos" */))
const _19da9ad7 = () => interopDefault(import('../pages/comments/_commentableType/_commentableId/new.vue' /* webpackChunkName: "pages/comments/_commentableType/_commentableId/new" */))
const _334c19e0 = () => interopDefault(import('../pages/contests/_gymId/_contestId/print-results.vue' /* webpackChunkName: "pages/contests/_gymId/_contestId/print-results" */))
const _91da0034 = () => interopDefault(import('../pages/links/_linkableType/_linkableId/new.vue' /* webpackChunkName: "pages/links/_linkableType/_linkableId/new" */))
const _20095db8 = () => interopDefault(import('../pages/newsletters/_newsletterId/_newsletterName/edit.vue' /* webpackChunkName: "pages/newsletters/_newsletterId/_newsletterName/edit" */))
const _1d6b91a2 = () => interopDefault(import('../pages/newsletters/_newsletterId/_newsletterName/photos.vue' /* webpackChunkName: "pages/newsletters/_newsletterId/_newsletterName/photos" */))
const _56046764 = () => interopDefault(import('../pages/photos/_illustrableType/_illustrableId/new.vue' /* webpackChunkName: "pages/photos/_illustrableType/_illustrableId/new" */))
const _955a48c0 = () => interopDefault(import('../pages/reports/_reportableType/_reportableId/new.vue' /* webpackChunkName: "pages/reports/_reportableType/_reportableId/new" */))
const _1419a201 = () => interopDefault(import('../pages/versions/_versionType/_versionId/changes.vue' /* webpackChunkName: "pages/versions/_versionType/_versionId/changes" */))
const _adcde326 = () => interopDefault(import('../pages/videos/_viewableType/_viewableId/new.vue' /* webpackChunkName: "pages/videos/_viewableType/_viewableId/new" */))
const _49c50466 = () => interopDefault(import('../pages/words/_wordId/_slugName/edit.vue' /* webpackChunkName: "pages/words/_wordId/_slugName/edit" */))
const _e8f7c9e2 = () => interopDefault(import('../pages/photos/_illustrableType/_illustrableId/_photoId/edit.vue' /* webpackChunkName: "pages/photos/_illustrableType/_illustrableId/_photoId/edit" */))
const _774fdcef = () => interopDefault(import('../pages/videos/_viewableType/_viewableId/_videoId/edit.vue' /* webpackChunkName: "pages/videos/_viewableType/_viewableId/_videoId/edit" */))
const _800916f6 = () => interopDefault(import('../pages/gym-chains/_gymChainId/banner.vue' /* webpackChunkName: "pages/gym-chains/_gymChainId/banner" */))
const _3a2000a3 = () => interopDefault(import('../pages/gym-chains/_gymChainId/edit.vue' /* webpackChunkName: "pages/gym-chains/_gymChainId/edit" */))
const _537c9fc4 = () => interopDefault(import('../pages/gym-chains/_gymChainId/logo.vue' /* webpackChunkName: "pages/gym-chains/_gymChainId/logo" */))
const _95fb51ca = () => interopDefault(import('../pages/areas/_areaId/_areaName/add-crag.vue' /* webpackChunkName: "pages/areas/_areaId/_areaName/add-crag" */))
const _43fdcb24 = () => interopDefault(import('../pages/areas/_areaId/_areaName/edit.vue' /* webpackChunkName: "pages/areas/_areaId/_areaName/edit" */))
const _1ac619a6 = () => interopDefault(import('../pages/crags/_cragId/_cragName/add-on-area.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/add-on-area" */))
const _a918a58c = () => interopDefault(import('../pages/crags/_cragId/_cragName/add-on-guide-book.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/add-on-guide-book" */))
const _553f4e48 = () => interopDefault(import('../pages/crags/_cragId/_cragName/edit.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/edit" */))
const _38376ff4 = () => interopDefault(import('../pages/crags/_cragId/_cragName/new-pdf-guide-book.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/new-pdf-guide-book" */))
const _62970552 = () => interopDefault(import('../pages/crags/_cragId/_cragName/new-web-guide-book.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/new-web-guide-book" */))
const _55e90b8e = () => interopDefault(import('../pages/crags/_cragId/_cragName/approaches/new.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/approaches/new" */))
const _486271c0 = () => interopDefault(import('../pages/crags/_cragId/_cragName/maps/edit.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/maps/edit" */))
const _9c4e1e10 = () => interopDefault(import('../pages/crags/_cragId/_cragName/parks/new.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/parks/new" */))
const _3b631fa6 = () => interopDefault(import('../pages/crags/_cragId/_cragName/routes/new.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/routes/new" */))
const _cdaf0108 = () => interopDefault(import('../pages/crags/_cragId/_cragName/sectors/new.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/sectors/new" */))
const _41ccce2e = () => interopDefault(import('../pages/crags/_cragId/_cragName/approaches/_approachId/edit.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/approaches/_approachId/edit" */))
const _9e867666 = () => interopDefault(import('../pages/crags/_cragId/_cragName/parks/_parkId/edit.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/parks/_parkId/edit" */))
const _4eec0bbd = () => interopDefault(import('../pages/crags/_cragId/_cragName/routes/_cragRouteId/_cragRouteName/edit.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/routes/_cragRouteId/_cragRouteName/edit" */))
const _537af2a2 = () => interopDefault(import('../pages/crags/_cragId/_cragName/sectors/_cragSectorId/_cragSectorName/edit.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/sectors/_cragSectorId/_cragSectorName/edit" */))
const _24520a87 = () => interopDefault(import('../pages/crags/_cragId/_cragName/routes/_cragRouteId/_cragRouteName/ascents/new.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/routes/_cragRouteId/_cragRouteName/ascents/new" */))
const _68641d73 = () => interopDefault(import('../pages/crags/_cragId/_cragName/sectors/_cragSectorId/_cragSectorName/routes/new.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/sectors/_cragSectorId/_cragSectorName/routes/new" */))
const _6c6d676a = () => interopDefault(import('../pages/crags/_cragId/_cragName/routes/_cragRouteId/_cragRouteName/ascents/_ascentCragRouteId/edit.vue' /* webpackChunkName: "pages/crags/_cragId/_cragName/routes/_cragRouteId/_cragRouteName/ascents/_ascentCragRouteId/edit" */))
const _abf2f89a = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/add-crag.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/add-crag" */))
const _4b5a5df5 = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/cover.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/cover" */))
const _2c8003bc = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/edit.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/edit" */))
const _7dfc7f5f = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/place-of-sales/new.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/place-of-sales/new" */))
const _fc4d2312 = () => interopDefault(import('../pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/place-of-sales/_placeOfSaleId/edit.vue' /* webpackChunkName: "pages/guide-book-papers/_guideBookPaperId/_guideBookPaperName/place-of-sales/_placeOfSaleId/edit" */))
const _28c8cb86 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/administration-request.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/administration-request" */))
const _5a671e85 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/administrator-required.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/administrator-required" */))
const _6989736d = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/index" */))
const _f413aedc = () => interopDefault(import('../pages/gyms/_gymId/_gymName/banner.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/banner" */))
const _476253f0 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/edit" */))
const _60bef311 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/logo.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/logo" */))
const _1f3ea0c0 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/ranking.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/ranking" */))
const _52b2d75b = () => interopDefault(import('../pages/gyms/_gymId/_gymName/select-space.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/select-space" */))
const _095d9d56 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/index" */))
const _36727104 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/administrators/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/administrators/index" */))
const _7627284c = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/championships/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/championships/index" */))
const _1d1afd80 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/climbing-styles/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/climbing-styles/index" */))
const _bf53f328 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/comments/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/comments/index" */))
const _c11226d2 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/index" */))
const _4925097f = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/first-difficulty-system.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/first-difficulty-system" */))
const _20560c84 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/first-space.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/first-space" */))
const _6759e598 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/grades/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/grades/index" */))
const _08e59ff4 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/indoor-subscriptions/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/indoor-subscriptions/index" */))
const _02a4cdbe = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/label-templates/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/label-templates/index" */))
const _4f981367 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/levels/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/levels/index" */))
const _825e2e0c = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/openers/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/openers/index" */))
const _19e36cfe = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/opening-sheets/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/opening-sheets/index" */))
const _4469f36b = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/ranking-systems/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/ranking-systems/index" */))
const _7786774a = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/tree-structures.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/tree-structures" */))
const _8795abe0 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/videos/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/videos/index" */))
const _40f4d224 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/new" */))
const _4572664c = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/administrators/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/administrators/new" */))
const _451ebacc = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/championships/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/championships/new" */))
const _882a04b6 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/new" */))
const _00bebcfc = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/grades/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/grades/new" */))
const _439cfc8c = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/label-templates/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/label-templates/new" */))
const _b5629270 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/openers/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/openers/new" */))
const _48a88d0f = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/opening-sheets/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/opening-sheets/new" */))
const _100a2c23 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/routes/statistics.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/routes/statistics" */))
const _311d7c65 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/routes/tables.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/routes/tables" */))
const _78c25dc1 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/space-groups/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/space-groups/new" */))
const _37034e2f = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/spaces/edit-three-d.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/spaces/edit-three-d" */))
const _b74cc33e = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/three-d-assets/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/three-d-assets/new" */))
const _249dda8e = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/championships/_championshipId.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/championships/_championshipId" */))
const _b96e2b08 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/championships/_championshipId/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/championships/_championshipId/index" */))
const _76bde1c0 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/championships/_championshipId/results.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/championships/_championshipId/results" */))
const _44de185b = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/_contestId.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/_contestId" */))
const _1d8fae9e = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/_contestId/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/_contestId/index" */))
const _2cffb610 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/_contestId/banner.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/_contestId/banner" */))
const _4465ae24 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/_contestId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/_contestId/edit" */))
const _6656eb84 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/_contestId/participants.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/_contestId/participants" */))
const _6f715a7c = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/_contestId/results.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/_contestId/results" */))
const _a642bc80 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/contests/_contestId/time-line.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/contests/_contestId/time-line" */))
const _51afba1b = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/grades/_gymGradeId/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/grades/_gymGradeId/index" */))
const _1c38a4dc = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/label-templates/_gymLabelTemplateId/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/label-templates/_gymLabelTemplateId/index" */))
const _17c9dba2 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/opening-sheets/_gymOpeningSheetId/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/opening-sheets/_gymOpeningSheetId/index" */))
const _3b75ac91 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/administrators/_gymAdministratorId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/administrators/_gymAdministratorId/edit" */))
const _a4218c5e = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/grades/_gymGradeId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/grades/_gymGradeId/edit" */))
const _3d826d92 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/label-templates/_gymLabelTemplateId/print.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/label-templates/_gymLabelTemplateId/print" */))
const _40e19bda = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/openers/_gymOpenerId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/openers/_gymOpenerId/edit" */))
const _46600006 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/opening-sheets/_gymOpeningSheetId/print.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/opening-sheets/_gymOpeningSheetId/print" */))
const _253878ea = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/space-groups/_gymSpaceGroupId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/space-groups/_gymSpaceGroupId/edit" */))
const _9cb3094e = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/spaces/_gymSpaceId/edit-three-d.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/spaces/_gymSpaceId/edit-three-d" */))
const _34c59007 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/spaces/_gymSpaceId/take-three-d-picture.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/spaces/_gymSpaceId/take-three-d-picture" */))
const _5faabad4 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/three-d-assets/_threeDAssetId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/three-d-assets/_threeDAssetId/edit" */))
const _41bc4423 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/grades/_gymGradeId/grade-lines/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/grades/_gymGradeId/grade-lines/new" */))
const _540def3c = () => interopDefault(import('../pages/gyms/_gymId/_gymName/admins/grades/_gymGradeId/grade-lines/_gymGradeLineId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/admins/grades/_gymGradeId/grade-lines/_gymGradeLineId/edit" */))
const _486493c3 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/contests/rankers/_contestId/_contestName/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/contests/rankers/_contestId/_contestName/index" */))
const _97f1a1ea = () => interopDefault(import('../pages/gyms/_gymId/_gymName/championships/_championshipId/_championshipName/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/championships/_championshipId/_championshipName/index" */))
const _a41543d6 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/contests/_contestId/_contestName.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/contests/_contestId/_contestName" */))
const _d2b37e50 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/contests/_contestId/_contestName/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/contests/_contestId/_contestName/index" */))
const _8e8a1d46 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/contests/_contestId/_contestName/my-contest.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/contests/_contestId/_contestName/my-contest" */))
const _56023e7c = () => interopDefault(import('../pages/gyms/_gymId/_gymName/contests/_contestId/_contestName/results.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/contests/_contestId/_contestName/results" */))
const _076e13f2 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/index.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/index" */))
const _0ea2c91a = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/edit" */))
const _11f151f7 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/select-sector.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/select-sector" */))
const _3a25bf7d = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/upload-3d.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/upload-3d" */))
const _0603d315 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/upload-plan.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/upload-plan" */))
const _7dd0ed7e = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/sectors/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/sectors/new" */))
const _2775c656 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/routes/_gymRouteId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/routes/_gymRouteId/edit" */))
const _584a3962 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/routes/_gymRouteId/picture.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/routes/_gymRouteId/picture" */))
const _4a44f960 = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/routes/_gymRouteId/thumbnail.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/routes/_gymRouteId/thumbnail" */))
const _50f587fa = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/sectors/_gymSectorId/edit.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/sectors/_gymSectorId/edit" */))
const _11ff50cb = () => interopDefault(import('../pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/sectors/_gymSectorId/routes/new.vue' /* webpackChunkName: "pages/gyms/_gymId/_gymName/spaces/_gymSpaceId/_gymSpaceName/sectors/_gymSectorId/routes/new" */))
const _27ef3dfe = () => interopDefault(import('../pages/organizations/_organizationId/_organizationName/edit.vue' /* webpackChunkName: "pages/organizations/_organizationId/_organizationName/edit" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _001fb762,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"À propos d'Oblyk","metaDescription":"En savoir plus sur Oblyk, pourquoi et qui est derrière le projet"},"en":{"metaTitle":"About Oblyk","metaDescription":"Learn more about Oblyk, why and who is behind the project"}}}},
    name: "about"
  }, {
    path: "/api-and-developers",
    component: _67a9c583,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"API des falaises et voies d'escalades","metaDescription":"Les API d'Oblyk vous permettes de consulter une grande base de donnée des falaises et voies d'escalade en France et dans le monde"},"en":{"metaTitle":"Climbing crags and routes API","metaDescription":"Oblyk's APIs allow you to consult a large database of crags and climbing routes in France and in the world"}}}},
    name: "api-and-developers"
  }, {
    path: "/articles",
    component: _60a14dd1,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Les actualités d'Oblyk","metaDescription":"Voir toutes les actualités d'Oblyk, la communauté de grimpeur"},"en":{"metaTitle":"Oblyk news","metaDescription":"View all Oblyk news, the climbing community"}}}},
    name: "articles"
  }, {
    path: "/contact",
    component: _294f381f,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Contacter l'équipe d'Oblyk","metaDescription":"Pour toute question, toute proposition, ou tout simplement pour nous aider, n'hésitez pas à nous contacter !"},"en":{"metaTitle":"Contact the Oblyk team","metaDescription":"For any question, any proposal, or simply to help us, do not hesitate to contact us!"}}},"test":true},
    name: "contact"
  }, {
    path: "/contests",
    component: _478346f3,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Les contests","metaDescription":"Trouver et participer à un contest organiser par une salles d'escalade"},"en":{"metaTitle":"Opens","metaDescription":"Find and take part in a competition organised by a climbing gym"}}}},
    name: "contests"
  }, {
    path: "/delete-account",
    component: _d6e56c48,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Supprimer mon compte","metaDescription":"Supprimez votre compte et toutes les données qui s'y rapportent"},"en":{"metaTitle":"Delete my account","metaDescription":"Delete your account and all related data"}}},"test":true},
    name: "delete-account"
  }, {
    path: "/glossary",
    component: _b5570da2,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Lexique des terms d'escalade","metaDescription":"Le lexique communautaire des termes de l'escalade pour décoder le parler grimpeur·euse"},"en":{"metaTitle":"Glossary of climbing terms","metaDescription":"The community lexicon of climbing terms to decode the climber's language"}}}},
    name: "glossary"
  }, {
    path: "/go-fly-a-kite",
    component: _1fb1f499,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Vous êtes un robot ?","metaDescription":"Vous avez un comportement anormal, nous vous avons bloqué."},"en":{"metaTitle":"You are robot?","metaDescription":"You have an abnormal behavior, we blocked you"}}}},
    name: "go-fly-a-kite"
  }, {
    path: "/grades",
    component: _652415cd,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Les cotations sur Oblyk","metaDescription":"Oblyk permet plusieurs notations pour la cotations et un code couleur est associé à une difficulté, voici comment ça marche"},"en":{"metaTitle":"Grades on oblyk","metaDescription":"Oblyk allows several notations for scoring and a color code is associated with a difficulty, here is how it works"}}}},
    name: "grades"
  }, {
    path: "/gym-administrations",
    component: _c737c676,
    meta: {"middleware":["auth","admin"]},
    name: "gym-administrations"
  }, {
    path: "/home",
    component: _41ad5273,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mon profil Oblyk"},"en":{"metaTitle":"My Oblyk profile"}}}},
    name: "home"
  }, {
    path: "/library",
    component: _0288733a,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Topos d'escalade","metaDescription":"Les topos de France et du monde : infos, falaises, carte des sites"},"en":{"metaTitle":"Climbing guides","metaDescription":"Guides of France and the world: information, cliffs, map of sites"}}}},
    name: "library"
  }, {
    path: "/maintenance",
    component: _8b496bdc,
    meta: {"layout":"blank"},
    name: "maintenance"
  }, {
    path: "/merci",
    component: _2cabd502,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Merci !","metaDescription":"Un énorme merci pour votre don ! Grâce à vous, Oblyk peut continuer à se développer et envisager l'avenir plus sereinement."},"en":{"metaTitle":"Thanks !","metaDescription":"A huge thank you for your donation! Thanks to you, Oblyk can continue to develop and look forward to a brighter future."}}}},
    name: "merci"
  }, {
    path: "/new",
    component: _dcd6873c,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Enrichir Oblyk"},"en":{"metaTitle":"Enrich Oblyk"}}}},
    name: "new"
  }, {
    path: "/new-password",
    component: _c0e7c48e,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Changer mon mot de passe"},"en":{"metaTitle":"Change my password"}}}},
    name: "new-password"
  }, {
    path: "/newsletters",
    component: _19c363c8,
    meta: {},
    name: "newsletters"
  }, {
    path: "/notifications",
    component: _5881ee2c,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mes notifications"},"en":{"metaTitle":"My notifications"}}}},
    name: "notifications"
  }, {
    path: "/oblyk",
    component: _7a5e53ec,
    meta: {"name":"OblykView","i18n":{"messages":{"fr":{"metaTitle":"Oblyk - Escalade, Carnet de croix & Carte des grimpeur·euse·s","metaDescription":"Oblyk est un site communautaire d'escalade outdoor et indoor, on peut : voir les informations des falaises, noter ses croix, trouver un partenaire d'escalade, etc."},"en":{"metaTitle":"Oblyk - Climbing community, Log book & Partner search","metaDescription":"Oblyk is an outdoor and indoor climbing community site, you can: see crag information, keep a logbook, find a climbing partner, etc."}}}},
    name: "oblyk"
  }, {
    path: "/privacy",
    component: _ff0978f2,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ma confidentialité"},"en":{"metaTitle":"My privacy"}}}},
    name: "privacy"
  }, {
    path: "/report-problem",
    component: _5df6c357,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Signaler un problème","metaDescription":"Vous avez trouvé un problème? dit le nous pour qu'on le corrige"},"en":{"metaTitle":"Report a problem","metaDescription":"Have you found a problem? Tell us so we can fix it"}}}},
    name: "report-problem"
  }, {
    path: "/reset-password",
    component: _3264f7ac,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mot de passe oublié ?","metaDescription":"Vous avez oublié votre mot de passe ? renseignez votre adresse mail pour le réinitialiser"},"en":{"metaTitle":"Forgot your password?","metaDescription":"Forgot your password? Enter your email address to reset it"}}}},
    name: "reset-password"
  }, {
    path: "/sign-in",
    component: _e83fb698,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Me connecter","metaDescription":"Me connecter à mon compte Oblyk et retrouver toutes les infos de la grimpes autour de chez moi"},"en":{"metaTitle":"Connect me","metaDescription":"Connect me to my Oblyk account and find all informations about the climbing around me"}}}},
    name: "sign-in"
  }, {
    path: "/sign-up",
    component: _bf1309ac,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Me créer un compte","metaDescription":"Me créer un mon compte pour suivre ma progression, trouver des partenaire d'escalade et avoir des infos local de la communauté de la grimpe"},"en":{"metaTitle":"Create an account","metaDescription":"Create an account to track my progress, find climbing partners and get local info from the climbing community"}}}},
    name: "sign-up"
  }, {
    path: "/success-account-deleting",
    component: _733ae23a,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Compte supprimé"},"en":{"metaTitle":"Account deleted"}}}},
    name: "success-account-deleting"
  }, {
    path: "/support-us",
    component: _7fcb572d,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Supporter Oblyk, faire un don","metaDescription":"Supporter le développement d'Oblyk pour qu'il puisse continuer à évoluer"},"en":{"metaTitle":"Support Oblyk, make a donation","metaDescription":"Support the development of Oblyk so that it can continue to evolve"}}}},
    name: "support-us"
  }, {
    path: "/about/indoor",
    component: _0354a226,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Créer le topo indoor de votre salle d'escalade","metaDescription":"Organisez votre salle d'escalade en espace et secteur, permettez à vos grimpeurs et grimpeuses de noter leur croix, supervisez vos ouvertures, et pleins d'autres fonctionnalités"},"en":{"metaTitle":"Create the indoor guidebook of your climbing gym","metaDescription":"Organize your climbing gym in space and sector, allow your climbers to rate their ascents, supervise your openings, and many other features"}}}},
    name: "about-indoor"
  }, {
    path: "/about/partner-search",
    component: _66d49620,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Recherche de partenaire d'escalade, comment ça marche ?","metaDescription":"La recherche de partenaire d'escalade te permet de trouver des grimpeur·euse·s autours de toi qui cherche aussi des gens pour grimper. Comment ça fonctionne ?"},"en":{"metaTitle":"Search for a climbing partner, how does it work?","metaDescription":"The climbing partner search allows you to find climbers around you who are also looking for people to climb with. How does it work?"}}}},
    name: "about-partner-search"
  }, {
    path: "/areas/new",
    component: _6268d856,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Créer un groupe de site"},"en":{"metaTitle":"Create crags group"}}},"orphanRoute":true},
    name: "areas-new"
  }, {
    path: "/articles/new",
    component: _20d0655f,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "articles-new"
  }, {
    path: "/ascents/new",
    component: _07e4962e,
    meta: {},
    name: "ascents-new"
  }, {
    path: "/crags/new",
    component: _b4d67de4,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter une falaise"},"en":{"metaTitle":"Create new crag"}}},"orphanRoute":true},
    name: "crags-new"
  }, {
    path: "/crags/search",
    component: _c49ed0ac,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Recherche avancée de sites d'escalades : trouver des sites d'escalades en France et dans le monde","metaDescription":"Trouver exactement le site d'escalade que vous chercher, filter avec le niveau du site, l'orientation, le temps de marche, etc."},"en":{"metaTitle":"Advanced climbing crag search : find climbing crags in France and in the world","metaDescription":"Find exactly the climbing crag you are looking for, filter with the level of the crag, the orientation, the walking time, etc."}}}},
    name: "crags-search"
  }, {
    path: "/en/cookie-and-audience-measurement",
    component: _6417ef46,
    meta: {},
    name: "en-cookie-and-audience-measurement"
  }, {
    path: "/en/terms-of-use",
    component: _2394d5bc,
    meta: {},
    name: "en-terms-of-use"
  }, {
    path: "/errors/right-required",
    component: _33e1d513,
    meta: {},
    name: "errors-right-required"
  }, {
    path: "/escalade-en/france",
    component: _0bbff98a,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Escalade en France: falaises, topos et salles d'escalade","metaDescription":"Retrouve les informations sur les sites d'escalade en France (voie, grande voie, bloc, via ferrata ...), les topos et les salles d'escalade sur Oblyk"},"en":{"metaTitle":"Climbing in France: crags, guide books and climbing gyms","metaDescription":"Find information on climbing sites in France (routes, multi pitches, boulder, via ferrata ...), guide books and climbing gyms on Oblyk"}}}},
    name: "escalade-en-france"
  }, {
    path: "/find/climbers",
    component: _22bc3534,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Trouver un grimpeur ou une grimpeuse","metaDescription":"Trouver un ou une partenaire de grimpe avec Oblyk"},"en":{"metaTitle":"Find a climber","metaDescription":"Find a climbing partner with Oblyk"}}}},
    name: "find-climbers"
  }, {
    path: "/find/crags",
    component: _37f1a38e,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Trouver un site d'escalade","metaDescription":"Trouver le site d'escalade que vous cherchez avec la carte des falaises du monde, ou autour d'une ville ou selon des critères bien particulier grâce à nos outils de recherche."},"en":{"metaTitle":"Find a climbing site","metaDescription":"Find the climbing crag you are looking for with the map of the world's crags, or around a city or according to specific criteria with our search tools."}}}},
    name: "find-crags"
  }, {
    path: "/find/guide-books",
    component: _79615658,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Trouver le topo d'escalade que vous cherchez","metaDescription":"Chercher un topo d'escalade autour d'une ville, consulté les dernières nouveauté, fait votre topothèque, etc."},"en":{"metaTitle":"Find the climbing guidebook you are looking for","metaDescription":"Search for a climbing guide book around a city, check the latest news, make your own guidebook library, etc."}}}},
    name: "find-guide-books"
  }, {
    path: "/find/gyms",
    component: _edf1e826,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Trouver une salle d'escalade","metaDescription":"Trouver une salle d'escalade autour de votre ville grâce à la carte des salle d'escalade ou en recherchant autour d'un lieux"},"en":{"metaTitle":"Find a climbing gym","metaDescription":"Find a climbing gym around your city with the climbing gym map or by searching around a location"}}}},
    name: "find-gyms"
  }, {
    path: "/fr/conditions-d-utilisation",
    component: _72cd8a26,
    meta: {},
    name: "fr-conditions-d-utilisation"
  }, {
    path: "/fr/cookie-et-mesure-d-audience",
    component: _11dfa7a6,
    meta: {},
    name: "fr-cookie-et-mesure-d-audience"
  }, {
    path: "/guide-book-papers/find",
    component: _44407102,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Trouver un topo d'escalade","metaDescription":"Trouves les topos d'escalades qu'il y a autour d'une ville ou d'un lieu en France et dans le monde"},"en":{"metaTitle":"Find a climbing guide","metaDescription":"Find the climbing topos that are around a city or a place in France and in the world"}}}},
    name: "guide-book-papers-find"
  }, {
    path: "/guide-book-papers/new",
    component: _7ea3baca,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un topo"},"en":{"metaTitle":"Add guide book"}}},"orphanRoute":true},
    name: "guide-book-papers-new"
  }, {
    path: "/guide-book-papers/new-versions",
    component: _79daa06e,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Topos intéressants","metaDescription":"Nous trouvons les topos qui pourrais t'intéresser par rapport à ton carnet de croix"},"en":{"metaTitle":"Interesting guide books","metaDescription":"We find the guide books that might interest you in relation to your log book"}}}},
    name: "guide-book-papers-new-versions"
  }, {
    path: "/guide-book-papers/recommended",
    component: _883d2ff6,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Topos intéressants","metaDescription":"Nous trouvons les topos qui pourrais t'intéresser par rapport à ton carnet de croix"},"en":{"metaTitle":"Interesting guide books","metaDescription":"We find the guide books that might interest you in relation to your log book"}}}},
    name: "guide-book-papers-recommended"
  }, {
    path: "/guide-books/features",
    component: _752e6c04,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Topos d'escalades et fonctionnalités","metaDescription":"Oblyk propose de nombreux outils permettant de trouver les topos, connaître les sites d'escalade qu'ils présentent, avoir une topothèque numérique, etc."},"en":{"metaTitle":"Climbing guides and features","metaDescription":"Oblyk offers many tools for finding guides, knowing the climbing crags they present, having a digital topo library, etc."}}}},
    name: "guide-books-features"
  }, {
    path: "/gyms/new",
    component: _4df492ba,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter une nouvelle salle d'escalade"},"en":{"metaTitle":"Add a new climbing gym"}}},"orphanRoute":true},
    name: "gyms-new"
  }, {
    path: "/gyms/starter",
    component: _53ffedae,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Créer le topo de ma salle","metaDescription":"Créer le topo de votre salle d'escalade gratuitement en quelques minutes"},"en":{"metaTitle":"Create a guidebook of my climbing gym","metaDescription":"Create a free climbing gym guidebook in a few minutes"}}}},
    name: "gyms-starter"
  }, {
    path: "/home/climbing-sessions",
    component: _701a1001,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mes sessions de grimpe","metaDescription":"La liste de toutes mes sessions de grimpe"},"en":{"metaTitle":"My climbing sessions","metaDescription":"The list of all my climbing sessions"}}}},
    name: "home-climbing-sessions"
  }, {
    path: "/home/community",
    component: _0746c98a,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ma communauté"},"en":{"metaTitle":"My community"}}}},
    name: "home-community",
    children: [{
      path: "followers",
      component: _234918d0,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes abonnées"},"en":{"metaTitle":"My followers"}}}},
      name: "home-community-followers"
    }, {
      path: "subscribes",
      component: _02bdbb84,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes abonnées"},"en":{"metaTitle":"My followers"}}}},
      name: "home-community-subscribes"
    }]
  }, {
    path: "/home/favorites",
    component: _190bc610,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mes favorites"},"en":{"metaTitle":"My favorites"}}}},
    name: "home-favorites",
    children: [{
      path: "crags",
      component: _08b4a632,
      meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mes falaises"},"en":{"metaTitle":"My crags"}}}},
      name: "home-favorites-crags"
    }, {
      path: "gyms",
      component: _05320102,
      meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mes salles"},"en":{"metaTitle":"My gyms"}}}},
      name: "home-favorites-gyms"
    }]
  }, {
    path: "/home/guide-books",
    component: _b60b2a06,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Topothèque"},"en":{"metaTitle":"Library"}}}},
    name: "home-guide-books"
  }, {
    path: "/home/media",
    component: _1d387ef6,
    meta: {"name":"CurrentUserMediaView","props":{"user":{"required":true}}},
    name: "home-media",
    children: [{
      path: "photos",
      component: _4186c17b,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes photos"},"en":{"metaTitle":"My pictures"}}}},
      name: "home-media-photos"
    }, {
      path: "videos",
      component: _014d1e5c,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes vidéos"},"en":{"metaTitle":"My videos"}}}},
      name: "home-media-videos"
    }]
  }, {
    path: "/home/messenger",
    component: _7a7aafb4,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ma messagerie"},"en":{"metaTitle":"My messenger"}}}},
    name: "home-messenger",
    children: [{
      path: "new",
      component: _002c4f76,
      meta: {"i18n":{"messages":{"fr":{"metaTitle":"Nouvelle conversation"},"en":{"metaTitle":"New conversation"}}}},
      name: "home-messenger-new"
    }, {
      path: ":conversationId",
      component: _585ee8c5,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Conversation avec %{name}"},"en":{"metaTitle":"Conversation with %{name}"}}}},
      name: "home-messenger-conversationId"
    }]
  }, {
    path: "/home/search-climbers",
    component: _b77c1512,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ma recherche de partenaire"},"en":{"metaTitle":"My partner search"}}}},
    name: "home-search-climbers"
  }, {
    path: "/home/settings",
    component: _1082e6dc,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Paramètres"},"en":{"metaTitle":"Settings"}}}},
    name: "home-settings",
    children: [{
      path: "avatar",
      component: _05686cb4,
      meta: {"i18n":{"messages":{"fr":{"metaTitle":"Changer mon avatar"},"en":{"metaTitle":"Change my avatar"}}}},
      name: "home-settings-avatar"
    }, {
      path: "banner",
      component: _5055340e,
      meta: {"i18n":{"messages":{"fr":{"metaTitle":"Changer mon bandeau"},"en":{"metaTitle":"Change my banner"}}}},
      name: "home-settings-banner"
    }, {
      path: "connection",
      component: _6d8f1a2b,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes paramètres de connexion"},"en":{"metaTitle":"My connection parameters"}}}},
      name: "home-settings-connection"
    }, {
      path: "general",
      component: _68fcb82a,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Modifier mon profil"},"en":{"metaTitle":"Edit my profile"}}}},
      name: "home-settings-general"
    }, {
      path: "notifications",
      component: _7ecef3ab,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes paramètres de notification"},"en":{"metaTitle":"My notification parameters"}}}},
      name: "home-settings-notifications"
    }, {
      path: "others",
      component: _0b8256b0,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Autre paramètres"},"en":{"metaTitle":"Other parameters"}}}},
      name: "home-settings-others"
    }, {
      path: "partner",
      component: _5d8ef06a,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Ma présence sur la carte des grimpeurs"},"en":{"metaTitle":"My presence on the climbers' map"}}}},
      name: "home-settings-partner"
    }, {
      path: "privacy",
      component: _1e8922ea,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes paramètres de confidentialité"},"en":{"metaTitle":"My privacy parameters"}}}},
      name: "home-settings-privacy"
    }]
  }, {
    path: "/maps/climbers",
    component: _066dad48,
    meta: {"name":"PartnerMapView","i18n":{"messages":{"fr":{"metaTitle":"La carte des grimpeur·euse·s d'Oblyk","metaDescription":"La carte des grimpeur·euse·s te permet de trouver un·e partenaire d'escalade près de chez toi. localise toi, trouve un·e grimpeur·euse, contact le·la et allez grimper ensemble !"},"en":{"metaTitle":"Map of Oblyk climbers","metaDescription":"The climber map allows you to find a climbing partner near you. Locate yourself, find a climber, contact him and go climbing together!"}}}},
    name: "maps-climbers"
  }, {
    path: "/maps/crags",
    component: _65c94197,
    meta: {"name":"CragMapView","i18n":{"messages":{"fr":{"metaTitle":"Carte des sites d'escalade en France et ailleurs","metaDescription":"Voir la carte interactive des sites naturels de grimpe sur Oblyk, que ce soit en France, ou dans le Monde, et voir leurs informations détaillées"},"en":{"metaTitle":"Map of climbing sites in France and elsewhere","metaDescription":"See the interactive map of natural climbing sites on Oblyk, either in France, or in the World, and see their detailed information"}}}},
    name: "maps-crags"
  }, {
    path: "/maps/guide-book-papers",
    component: _3eda6253,
    meta: {"name":"GymMapView","i18n":{"messages":{"fr":{"metaTitle":"Carte des topos de France et ailleurs","metaDescription":"Voir la carte interactive des topos de grimpe sur Oblyk, que ce soit en France, ou dans le Monde, et voir leurs informations détaillées"},"en":{"metaTitle":"Map of climbing guide books in France and elsewhere","metaDescription":"See the interactive map of climbing guide books on Oblyk, either in France, or in the World, and see their detailed information"}}}},
    name: "maps-guide-book-papers"
  }, {
    path: "/maps/gyms",
    component: _4b2614cf,
    meta: {"name":"GymMapView","i18n":{"messages":{"fr":{"metaTitle":"Carte des salle d'escalade en France et ailleurs","metaDescription":"Voir la carte interactive des salle de grimpe sur Oblyk, que ce soit en France, ou dans le Monde, et voir leurs informations détaillées"},"en":{"metaTitle":"Map of climbing gyms in France and elsewhere","metaDescription":"See the interactive map of climbing gyms on Oblyk, either in France, or in the World, and see their detailed information"}}}},
    name: "maps-gyms"
  }, {
    path: "/maps/my-map",
    component: _16e2699c,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ma carte"},"en":{"metaTitle":"My map"}}}},
    name: "maps-my-map"
  }, {
    path: "/newsletters/new",
    component: _0a96fc6a,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "newsletters-new"
  }, {
    path: "/newsletters/subscribe",
    component: _4f9a44d8,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Recevoir la newsletter d'Oblyk","metaDescription":"Abonnez vous à la newsletter d'Oblyk pour recevoir de temps en temps des nouvelles de la communauté grimpante"},"en":{"metaTitle":"Receive the Oblyk newsletter","metaDescription":"Subscribe to the Oblyk newsletter to receive occasional news from the climbing community"}}}},
    name: "newsletters-subscribe"
  }, {
    path: "/newsletters/successful-subscribe",
    component: _53f11afd,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Vous êtes abonnées à la newsletter","metaDescription":"Vous êtes désormais inscrit à notre newsletter, à bientôt dans votre boîte mail"},"en":{"metaTitle":"You are subscribed to the newsletter","metaDescription":"You are now subscribed to our newsletter, see you soon in your mailbox"}}}},
    name: "newsletters-successful-subscribe"
  }, {
    path: "/newsletters/successful-unsubscribe",
    component: _17f5b244,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Vous n'êtes plus inscrit à la newsletter","metaDescription":"Vous être désinscrit de la newsletter, à bientôt"},"en":{"metaTitle":"You are no longer subscribed to the newsletter","metaDescription":"You are unsubscribed from the newsletter, see you soon"}}}},
    name: "newsletters-successful-unsubscribe"
  }, {
    path: "/newsletters/unsubscribe",
    component: _da5da9ca,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Se désinscrire de la newsletter","metaDescription":"Vous ne voulez plus recevoir notre newsletter, c'est ici que ça se passe"},"en":{"metaTitle":"Unsubscribe from the newsletter","metaDescription":"You don't want to receive our newsletter anymore, this is where it happens"}}}},
    name: "newsletters-unsubscribe"
  }, {
    path: "/organizations/new",
    component: _5f71ff70,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Enregistrer mon organisation"},"en":{"metaTitle":"Register my organization"}}},"orphanRoute":true},
    name: "organizations-new"
  }, {
    path: "/words/new",
    component: _370fde79,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Nouvelle définition"},"en":{"metaTitle":"New definition"}}},"orphanRoute":true},
    name: "words-new"
  }, {
    path: "/ascents/indoor/new",
    component: _0cd48aaa,
    meta: {},
    name: "ascents-indoor-new"
  }, {
    path: "/ascents/outdoor/new",
    component: _36cc70d6,
    meta: {"orphanRoute":true},
    name: "ascents-outdoor-new"
  }, {
    path: "/home/ascents/indoor",
    component: _dc14620a,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mes croix indoor"},"en":{"metaTitle":"My indoor ascents"}}}},
    name: "home-ascents-indoor"
  }, {
    path: "/home/ascents/outdoor",
    component: _51509727,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mes croix outdoor"},"en":{"metaTitle":"My outdoor ascents"}}}},
    children: [{
      path: "",
      component: _0522e46a,
      meta: {"name":"CurrentUserSendListView","props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes croix outdoor"},"en":{"metaTitle":"My outdoor ascents"}}}},
      name: "home-ascents-outdoor"
    }, {
      path: "analytiks",
      component: _23d8bbd4,
      meta: {"name":"CurrentUserAnalytiksView","props":{"user":{"required":true}}},
      name: "home-ascents-outdoor-analytiks"
    }, {
      path: "projects",
      component: _409407dc,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Mes projets"},"en":{"metaTitle":"My projects"}}}},
      name: "home-ascents-outdoor-projects"
    }, {
      path: "tick-list",
      component: _7b2f5866,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Ma tick-list"},"en":{"metaTitle":"My tick-list"}}}},
      name: "home-ascents-outdoor-tick-list"
    }]
  }, {
    path: "/home/guide-books/add",
    component: _5e75440c,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un topo"},"en":{"metaTitle":"Add guide book"}}}},
    name: "home-guide-books-add"
  }, {
    path: "/",
    component: _5f28c191,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Oblyk - Escalade, Carnet de croix & Carte des grimpeur·euse·s","metaDescription":"Oblyk est un site communautaire d'escalade outdoor et indoor, on peut : voir les informations des falaises, noter ses croix, trouver un partenaire d'escalade, etc."},"en":{"metaTitle":"Oblyk - Climbing community, Log book & Partner search","metaDescription":"Oblyk is an outdoor and indoor climbing community site, you can: see crag information, keep a logbook, find a climbing partner, etc."}}}},
    name: "index"
  }, {
    path: "/home/climbing-sessions/:sessionDate",
    component: _33b50649,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ma session de grimpe du %{date}","metaDescription":"Les croix, lieux, grimpeurs et grimpeuses avec qui j'ai grimpé le %{date}"},"en":{"metaTitle":"My climbing session on %{date}","metaDescription":"My ascents, places, climbers with whom I climbed on %{date}"}}}},
    name: "home-climbing-sessions-sessionDate"
  }, {
    path: "/escalade-en/france/:departmentNumber?/:departementName",
    component: _ca860aea,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Escalade %{name} : falaises, topos et salles d'escalade","metaDescription":"Retrouve les informations sur les sites d'escalade %{name} (voie, grande voie, bloc, via ferrata ...), les topos et les salles d'escalade sur Oblyk"},"en":{"metaTitle":"Climbing %{name} : crags, guide book and climbing gyms","metaDescription":"Find information on climbing sites %{name} (routes, multi pitches, boulder, via ferrata ...), guide books and climbing gyms on Oblyk"}}}},
    name: "escalade-en-france-departmentNumber-departementName"
  }, {
    path: "/climbers/:userName?",
    component: _a767b8b0,
    meta: {},
    children: [{
      path: "",
      component: _ef6d67aa,
      meta: {"name":"UserProfileView","props":{"user":{"required":true}}},
      name: "climbers-userName"
    }, {
      path: "ascents",
      component: _e9526b1c,
      meta: {"props":{"user":{"required":true}}},
      name: "climbers-userName-ascents",
      children: [{
        path: "indoor",
        component: _fac71080,
        meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les croix outdoor de %{name}","metaDescription":"Consulter les croix outdoor faites par %{name} tout au long de sa vie de grimpeur·euse"},"en":{"metaTitle":"The outdoor ascents of %{name}","metaDescription":"Consult the outdoor ascents made by %{name} throughout his climbing life"}}}},
        name: "climbers-userName-ascents-indoor"
      }, {
        path: "outdoor",
        component: _6f2c6a42,
        meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les croix outdoor de %{name}","metaDescription":"Consulter les croix outdoor faites par %{name} tout au long de sa vie de grimpeur·euse"},"en":{"metaTitle":"The outdoor ascents of %{name}","metaDescription":"Consult the outdoor ascents made by %{name} throughout his climbing life"}}}},
        name: "climbers-userName-ascents-outdoor"
      }]
    }, {
      path: "followers",
      component: _409a3524,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les abonnés de %{name}","metaDescription":"Voir les grimpeur·euse·s abonnées à %{name}, grimpeur·euse de la communauté Oblyk"},"en":{"metaTitle":"The subscribers of %{name}","metaDescription":"See the climbers subscribed to %{name}, climber of the community Oblyk"}}}},
      name: "climbers-userName-followers"
    }, {
      path: "media",
      component: _37ffba3d,
      meta: {"props":{"user":{"required":true}}},
      name: "climbers-userName-media",
      children: [{
        path: "photos",
        component: _1508e8c3,
        meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Photos de %{name}","metaDescription":"Voir les photos d'escalade prise par %{name} sur les différentes falaises qu'iel a parcourut"},"en":{"metaTitle":"Photos of %{name}","metaDescription":"See the climbing pictures taken by %{name} on the different cliffs he climbed"}}}},
        name: "climbers-userName-media-photos"
      }, {
        path: "videos",
        component: _5a48cfcc,
        meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Videos de %{name}","metaDescription":"Voir les vidéos d'escalade prise par %{name} sur les différentes falaises qu'iel a parcourut"},"en":{"metaTitle":"Videos of %{name}","metaDescription":"See the climbing videos taken by %{name} on the different cliffs he climbed"}}}},
        name: "climbers-userName-media-videos"
      }]
    }, {
      path: "subscribes",
      component: _611e2d40,
      meta: {"props":{"user":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Abonnement de %{name}","metaDescription":"Voir les abonnements aux falaises, salles, et autre grimpeur·euse de %{name}"},"en":{"metaTitle":"Subscribe of %{name}","metaDescription":"View memberships to cliffs, gyms, and other climbers in %{name}"}}}},
      name: "climbers-userName-subscribes"
    }]
  }, {
    path: "/comments/:commentId",
    component: _56b88298,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Commentaire"},"en":{"metaTitle":"Comment"}}}},
    name: "comments-commentId"
  }, {
    path: "/escalade-autour-de/:townName",
    component: _45283b78,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Escalade %{name} : falaises, topos et salles d'escalade","metaDescription":"Retrouve les informations sur les sites d'escalade autour de %{name} (%{department_number}) : voie, grande voie, bloc, via ferrata ..., les topos et les salles d'escalade sur Oblyk"},"en":{"metaTitle":"Climbing %{name} : crags, guide book and climbing gyms","metaDescription":"Find information on climbing sites around %{name} (%{department_number}) : routes, multi pitches, boulder, via ferrata ..., guide books and climbing gyms on Oblyk"}}}},
    name: "escalade-autour-de-townName"
  }, {
    path: "/gr/:gymAndRouteId",
    component: _5319856f,
    meta: {},
    name: "gr-gymAndRouteId"
  }, {
    path: "/grs/:gymId",
    component: _708e5a52,
    meta: {},
    name: "grs-gymId"
  }, {
    path: "/gym-chains/:gymChainId?",
    component: _b112f8f4,
    meta: {},
    children: [{
      path: "",
      component: _4b97cd89,
      meta: {"props":{"gymChain":{"required":true}}},
      name: "gym-chains-gymChainId"
    }]
  }, {
    path: "/photos/:photoId",
    component: _7db930be,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Photo"},"en":{"metaTitle":"Photo"}}}},
    name: "photos-photoId"
  }, {
    path: "/videos/:videoId",
    component: _2e05b950,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Vidéo"},"en":{"metaTitle":"Video"}}}},
    name: "videos-videoId"
  }, {
    path: "/alerts/:alertId?/edit",
    component: _331c4c0c,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier l'alerte"},"en":{"metaTitle":"Edit alert"}}},"orphanRoute":true},
    name: "alerts-alertId-edit"
  }, {
    path: "/authors/:authorId?/cover",
    component: _019cbdc5,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter une couverture à l'auteur"},"en":{"metaTitle":"Add author cover"}}},"orphanRoute":true},
    name: "authors-authorId-cover"
  }, {
    path: "/authors/:authorId?/edit",
    component: _50eb2c28,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "authors-authorId-edit"
  }, {
    path: "/comments/:commentId?/edit",
    component: _82c62498,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier le commentaire"},"en":{"metaTitle":"Edit comment"}}},"orphanRoute":true},
    name: "comments-commentId-edit"
  }, {
    path: "/guide-book-pdfs/:guideBookPdfId?/edit",
    component: _572346fe,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier le topo PDF"},"en":{"metaTitle":"Edit PDF guide"}}},"orphanRoute":true},
    name: "guide-book-pdfs-guideBookPdfId-edit"
  }, {
    path: "/guide-book-webs/:guideBookWebId?/edit",
    component: _fea78184,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier le topo web"},"en":{"metaTitle":"Edit web guide"}}},"orphanRoute":true},
    name: "guide-book-webs-guideBookWebId-edit"
  }, {
    path: "/links/:linkId?/edit",
    component: _cb78e168,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "links-linkId-edit"
  }, {
    path: "/areas/:areaId?/:areaName?",
    component: _a15b2736,
    meta: {},
    children: [{
      path: "",
      component: _7d735328,
      meta: {"props":{"area":{"required":true}}},
      name: "areas-areaId-areaName"
    }, {
      path: "crags",
      component: _07cdf694,
      meta: {"props":{"area":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les falaises de %{name}, Groupe de sites d'escalade","metaDescription":"Voir les falaises du groupe de sites d'escalade de %{name}, quels spots de grimpe il présente ou encore quelle variété de cotations on y trouve"},"en":{"metaTitle":"Crags of %{name}, climbing crags group","metaDescription":"See the crags of the group of climbing sites of %{name}, what kind of climbing spots it offers or what variety of grades can be found there"}}}},
      name: "areas-areaId-areaName-crags"
    }, {
      path: "photos",
      component: _2c4e0cca,
      meta: {"props":{"area":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les photos de %{name}, Groupe de sites d'escalade","metaDescription":"Voir les photos du groupe de sites d'escalade de %{name}, quels spots de grimpe il présente ou encore quelle variété de cotations on y trouve"},"en":{"metaTitle":"Pictures of %{name}, climbing crags group","metaDescription":"See the pictures of the group of climbing sites of %{name}, what kind of climbing spots it offers or what variety of grades can be found there"}}}},
      name: "areas-areaId-areaName-photos"
    }]
  }, {
    path: "/articles/:articleId/:articleName",
    component: _bf576d3e,
    meta: {},
    name: "articles-articleId-articleName"
  }, {
    path: "/crag-routes/:cragRouteId?/:cragRouteName",
    component: _177830d8,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"%{name} %{grade}, %{cragName}","metaDescription":"%{name} %{grade}, %{type} faisant partie du site d'escalade %{cragName}. voir le detail de la ligne : cotation, photo, avis, etc."},"en":{"metaTitle":"%{name} %{grade}, %{cragName}","metaDescription":"%{name} %{grade}, %{type} forming part of the climbing site %{cragName}. see the details of the line: grade, photo, opinion, etc."}}}},
    name: "crag-routes-cragRouteId-cragRouteName"
  }, {
    path: "/crag-sectors/:cragSectorId?/:cragSectorName?",
    component: _95afc33c,
    meta: {},
    children: [{
      path: "",
      component: _f3ee5936,
      meta: {"props":{"cragSector":{"required":true}}},
      name: "crag-sectors-cragSectorId-cragSectorName"
    }, {
      path: "maps",
      component: _01035954,
      meta: {"name":"CragSectorMapView","props":{"cragSector":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"La carte de %{name}, secteur d'escalade de %{crag}","metaDescription":"La carte de  %{name}, secteur d'escalade %{crag} situé à %{city} en %{region}."},"en":{"metaTitle":"Map of %{name}, climbing sector of %{crag}","metaDescription":"Map of %{name}, climbing sector of %{crag} located at %{city} in %{region}"}}}},
      name: "crag-sectors-cragSectorId-cragSectorName-maps"
    }, {
      path: "photos",
      component: _1818fe04,
      meta: {"name":"CragSectorPhotosView","props":{"cragSector":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les photos de %{name}, secteur d'escalade de %{crag}","metaDescription":"Les photos de  %{name}, secteur d'escalade %{crag} situé à %{city} en %{region}."},"en":{"metaTitle":"Pictures of %{name}, climbing sector of %{crag}","metaDescription":"Pictures of %{name}, climbing sector of %{crag} located at %{city} in %{region}"}}}},
      name: "crag-sectors-cragSectorId-cragSectorName-photos"
    }]
  }, {
    path: "/crags/:cragId?/:cragName?",
    component: _520c2bad,
    meta: {},
    children: [{
      path: "",
      component: _18627c70,
      meta: {"props":{"crag":{"required":true}}},
      name: "crags-cragId-cragName"
    }, {
      path: "guide-books",
      component: _52a44037,
      meta: {"name":"CragGuideBooksView","props":{"crag":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les topos de %{name}, escalade en %{region}","metaDescription":"Les topos de %{name} : site d'escalade à %{city} en %{region}"},"en":{"metaTitle":"Climbing guide book of %{name}, climb in %{region}","metaDescription":"Climbing guide book of %{name} : climbing crag in %{city} in %{region}"}}}},
      name: "crags-cragId-cragName-guide-books"
    }, {
      path: "links",
      component: _2e825052,
      meta: {"name":"CragLinkView","props":{"crag":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les liens de %{name}, escalade en %{region}","metaDescription":"Les liens de %{name} : site d'escalade à %{city} en %{region}"},"en":{"metaTitle":"Links of %{name}, climb in %{region}","metaDescription":"Links of %{name} : climbing crag in %{city} in %{region}"}}}},
      name: "crags-cragId-cragName-links"
    }, {
      path: "maps",
      component: _407c52a8,
      meta: {"name":"CragMapDetailsView","props":{"crag":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"La carte de %{name}, escalade en %{region}","metaDescription":"La carte de %{name} : site d'escalade à %{city} en %{region}"},"en":{"metaTitle":"Map of %{name}, climb in %{region}","metaDescription":"Map of %{name} : climbing crag in %{city} in %{region}"}}}},
      name: "crags-cragId-cragName-maps"
    }, {
      path: "photos",
      component: _a6620d5a,
      meta: {"name":"CragPhotosView","props":{"crag":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les photos de %{name}, escalade en %{region}","metaDescription":"Les photos de %{name} : site d'escalade à %{city} en %{region}"},"en":{"metaTitle":"Pictures of %{name}, climb in %{region}","metaDescription":"Pictures of %{name} : climbing crag in %{city} in %{region}"}}}},
      name: "crags-cragId-cragName-photos"
    }, {
      path: "routes",
      component: _8a7045c2,
      meta: {"props":{"crag":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les voies de %{name}, site d'escalade en %{region}","metaDescription":"Retrouvez toutes les voies et blocs du site d'escalade de %{name}, les cotations et les avis des grimpeurs"},"en":{"metaTitle":"The routes of %{name}, climbing site in %{region}","metaDescription":"Find all the routes and boulders of the %{name} climbing site, the quotations and the climbers' opinions"}}}},
      name: "crags-cragId-cragName-routes"
    }]
  }, {
    path: "/guide-book-papers/:guideBookPaperId?/:guideBookPaperName?",
    component: _4e759666,
    meta: {},
    children: [{
      path: "",
      component: _b58fa4e0,
      meta: {"props":{"guideBookPaper":{"required":true}}},
      name: "guide-book-papers-guideBookPaperId-guideBookPaperName"
    }, {
      path: "alternatives",
      component: _0e0c1c18,
      meta: {"props":{"guideBookPaper":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Topos alternatifs à %{name}","metaDescription":"Liste des autres topos d'escalades qui traitent des mêmes sites que le topo %{name}"},"en":{"metaTitle":"Alternative guides to %{name}","metaDescription":"List of other climbing guidebooks that deal with the same crags as the guidebook %{name}"}}}},
      name: "guide-book-papers-guideBookPaperId-guideBookPaperName-alternatives"
    }, {
      path: "crags",
      component: _2f92d0fc,
      meta: {"name":"GuideBookPaperCragsView","props":{"guideBookPaper":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les falaises de %{name}, topo d'escalade","metaDescription":"Voir les falaises du topo d'escalade %{name}."},"en":{"metaTitle":"Crags of %{name}, climbing guide book","metaDescription":"See the crags of %{name} climbing guide book"}}}},
      name: "guide-book-papers-guideBookPaperId-guideBookPaperName-crags"
    }, {
      path: "links",
      component: _759488f7,
      meta: {"name":"GuideBookPaperLinkView","props":{"guideBookPaper":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les liens de %{name}, topo d'escalade","metaDescription":"Voir les liens du topo d'escalade %{name}."},"en":{"metaTitle":"Links of %{name}, climbing guide book","metaDescription":"See the links of %{name} climbing guide book"}}}},
      name: "guide-book-papers-guideBookPaperId-guideBookPaperName-links"
    }, {
      path: "map",
      component: _40ade69a,
      meta: {"name":"GuideBookPaperMapView","props":{"guideBookPaper":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"La carte de %{name}, topo d'escalade","metaDescription":"Voir la carte du topo d'escalade %{name}."},"en":{"metaTitle":"Map of %{name}, climbing guide book","metaDescription":"See the map of %{name} climbing guide book"}}}},
      name: "guide-book-papers-guideBookPaperId-guideBookPaperName-map"
    }, {
      path: "photos",
      component: _8aa1279a,
      meta: {"name":"GuideBookPaperPhotosView","props":{"guideBookPaper":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les photos de %{name}, topo d'escalade","metaDescription":"Voir les photos du topo d'escalade %{name}."},"en":{"metaTitle":"Pictures of %{name}, climbing guide book","metaDescription":"See the pictures of %{name} climbing guide book"}}}},
      name: "guide-book-papers-guideBookPaperId-guideBookPaperName-photos"
    }, {
      path: "points-of-sale",
      component: _50a73f76,
      meta: {"props":{"guideBookPaper":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les points de vente de %{name}, topo d'escalade","metaDescription":"Voir les points de vente du topo d'escalade %{name}."},"en":{"metaTitle":"Points of sale of %{name}, climbing guide book","metaDescription":"See the Points of sale of %{name} climbing guide book"}}}},
      name: "guide-book-papers-guideBookPaperId-guideBookPaperName-points-of-sale"
    }]
  }, {
    path: "/gyms/:gymId?/:gymName?",
    component: _56969419,
    meta: {},
    children: [{
      path: "",
      component: _32c03848,
      meta: {"props":{"gym":{"required":true}}},
      name: "gyms-gymId-gymName"
    }, {
      path: "followers",
      component: _7ca43c9f,
      meta: {"props":{"gym":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Les abonnés de %{name}","metaDescription":"Voir les grimpeurs et grimpeuses abonné·es à la salle d'escalade %{name}"},"en":{"metaTitle":"The subscribers of %{name}","metaDescription":"See the climbers subscribed to %{name} climbing gym"}}}},
      name: "gyms-gymId-gymName-followers"
    }]
  }, {
    path: "/newsletters/:newsletterId/:newsletterName",
    component: _22da1314,
    meta: {"i18n":{"messages":{"fr":{"metaDescription":"Consulté ici la newsletter que vous avez reçu dans votre boîte email"},"en":{"metaDescription":"Consulted here the newsletter you received in your email box"}}}},
    name: "newsletters-newsletterId-newsletterName"
  }, {
    path: "/organizations/:organizationId?/:organizationName?",
    component: _51e7194b,
    meta: {},
    children: [{
      path: "",
      component: _17b03b8e,
      meta: {"props":{"organization":{"required":true}}},
      name: "organizations-organizationId-organizationName"
    }]
  }, {
    path: "/words/:wordId?/:slugName",
    component: _30934226,
    meta: {},
    name: "words-wordId-slugName"
  }, {
    path: "/alerts/:alertableType?/:alertableId?/new",
    component: _1634974c,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Créer une alerte"},"en":{"metaTitle":"Create alert"}}},"orphanRoute":true},
    name: "alerts-alertableType-alertableId-new"
  }, {
    path: "/articles/:articleId/:articleName?/add-crags",
    component: _2f8d5f21,
    meta: {"orphanRoute":true},
    name: "articles-articleId-articleName-add-crags"
  }, {
    path: "/articles/:articleId/:articleName?/add-guide-books",
    component: _23d19088,
    meta: {"orphanRoute":true},
    name: "articles-articleId-articleName-add-guide-books"
  }, {
    path: "/articles/:articleId/:articleName?/cover",
    component: _467679c6,
    meta: {"orphanRoute":true},
    name: "articles-articleId-articleName-cover"
  }, {
    path: "/articles/:articleId/:articleName?/edit",
    component: _9f0eac6a,
    meta: {"orphanRoute":true},
    name: "articles-articleId-articleName-edit"
  }, {
    path: "/articles/:articleId/:articleName?/photos",
    component: _2316ca82,
    meta: {},
    name: "articles-articleId-articleName-photos"
  }, {
    path: "/comments/:commentableType?/:commentableId?/new",
    component: _19da9ad7,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un commentaire"},"en":{"metaTitle":"Add comment"}}},"orphanRoute":true},
    name: "comments-commentableType-commentableId-new"
  }, {
    path: "/contests/:gymId/:contestId?/print-results",
    component: _334c19e0,
    meta: {"layout":"blank"},
    name: "contests-gymId-contestId-print-results"
  }, {
    path: "/links/:linkableType?/:linkableId?/new",
    component: _91da0034,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un nouveau lien"},"en":{"metaTitle":"Add a new link"}}},"orphanRoute":true},
    name: "links-linkableType-linkableId-new"
  }, {
    path: "/newsletters/:newsletterId/:newsletterName?/edit",
    component: _20095db8,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "newsletters-newsletterId-newsletterName-edit"
  }, {
    path: "/newsletters/:newsletterId/:newsletterName?/photos",
    component: _1d6b91a2,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "newsletters-newsletterId-newsletterName-photos"
  }, {
    path: "/photos/:illustrableType?/:illustrableId?/new",
    component: _56046764,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter une photo"},"en":{"metaTitle":"Add a photo"}}}},
    name: "photos-illustrableType-illustrableId-new"
  }, {
    path: "/reports/:reportableType?/:reportableId?/new",
    component: _955a48c0,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Signaler un problème","metaDescription":"Vous avez trouvé un problème? dit le nous pour qu'on le corrige"},"en":{"metaTitle":"Report a problem","metaDescription":"Have you found a problem? Tell us so we can fix it"}}}},
    name: "reports-reportableType-reportableId-new"
  }, {
    path: "/versions/:versionType?/:versionId?/changes",
    component: _1419a201,
    meta: {"name":"VersionsView"},
    name: "versions-versionType-versionId-changes"
  }, {
    path: "/videos/:viewableType?/:viewableId?/new",
    component: _adcde326,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter une video"},"en":{"metaTitle":"Add a video"}}},"orphanRoute":true},
    name: "videos-viewableType-viewableId-new"
  }, {
    path: "/words/:wordId?/:slugName?/edit",
    component: _49c50466,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "words-wordId-slugName-edit"
  }, {
    path: "/photos/:illustrableType?/:illustrableId?/:photoId?/edit",
    component: _e8f7c9e2,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier la photo"},"en":{"metaTitle":"Edit photo"}}}},
    name: "photos-illustrableType-illustrableId-photoId-edit"
  }, {
    path: "/videos/:viewableType?/:viewableId?/:videoId?/edit",
    component: _774fdcef,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier la video"},"en":{"metaTitle":"Edit video"}}}},
    name: "videos-viewableType-viewableId-videoId-edit"
  }, {
    path: "/gym-chains/:gymChainId?/banner",
    component: _800916f6,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Changer le bandeau"},"en":{"metaTitle":"Edit banner"}}},"orphanRoute":true},
    name: "gym-chains-gymChainId-banner"
  }, {
    path: "/gym-chains/:gymChainId?/edit",
    component: _3a2000a3,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "gym-chains-gymChainId-edit"
  }, {
    path: "/gym-chains/:gymChainId?/logo",
    component: _537c9fc4,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Changer le logo"},"en":{"metaTitle":"Edit logo"}}},"orphanRoute":true},
    name: "gym-chains-gymChainId-logo"
  }, {
    path: "/areas/:areaId?/:areaName?/add-crag",
    component: _95fb51ca,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un site à %{name}"},"en":{"metaTitle":"Add crag in %{name}"}}},"orphanRoute":true},
    name: "areas-areaId-areaName-add-crag"
  }, {
    path: "/areas/:areaId?/:areaName?/edit",
    component: _43fdcb24,
    meta: {"orphanRoute":true},
    name: "areas-areaId-areaName-edit"
  }, {
    path: "/crags/:cragId?/:cragName?/add-on-area",
    component: _1ac619a6,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter %{name} à une zone"},"en":{"metaTitle":"Add %{name} in area"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-add-on-area"
  }, {
    path: "/crags/:cragId?/:cragName?/add-on-guide-book",
    component: _a918a58c,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un topo à %{name}"},"en":{"metaTitle":"Add paper guide at %{name}"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-add-on-guide-book"
  }, {
    path: "/crags/:cragId?/:cragName?/edit",
    component: _553f4e48,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "crags-cragId-cragName-edit"
  }, {
    path: "/crags/:cragId?/:cragName?/new-pdf-guide-book",
    component: _38376ff4,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un topo PDF"},"en":{"metaTitle":"Add PDF guide"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-new-pdf-guide-book"
  }, {
    path: "/crags/:cragId?/:cragName?/new-web-guide-book",
    component: _62970552,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un topo web"},"en":{"metaTitle":"Add web guide"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-new-web-guide-book"
  }, {
    path: "/crags/:cragId?/:cragName?/approaches/new",
    component: _55e90b8e,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Créer une approche"},"en":{"metaTitle":"Create approach"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-approaches-new"
  }, {
    path: "/crags/:cragId?/:cragName?/maps/edit",
    component: _486271c0,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "crags-cragId-cragName-maps-edit"
  }, {
    path: "/crags/:cragId?/:cragName?/parks/new",
    component: _9c4e1e10,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un parking"},"en":{"metaTitle":"Add a park"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-parks-new"
  }, {
    path: "/crags/:cragId?/:cragName?/routes/new",
    component: _3b631fa6,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter une ligne"},"en":{"metaTitle":"Add crag route"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-routes-new"
  }, {
    path: "/crags/:cragId?/:cragName?/sectors/new",
    component: _cdaf0108,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un secteur"},"en":{"metaTitle":"Add sector"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-sectors-new"
  }, {
    path: "/crags/:cragId?/:cragName?/approaches/:approachId?/edit",
    component: _41ccce2e,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier l'approche"},"en":{"metaTitle":"Edit approach"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-approaches-approachId-edit"
  }, {
    path: "/crags/:cragId?/:cragName?/parks/:parkId?/edit",
    component: _9e867666,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier le parking"},"en":{"metaTitle":"Edit park"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-parks-parkId-edit"
  }, {
    path: "/crags/:cragId?/:cragName?/routes/:cragRouteId/:cragRouteName?/edit",
    component: _4eec0bbd,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "crags-cragId-cragName-routes-cragRouteId-cragRouteName-edit"
  }, {
    path: "/crags/:cragId?/:cragName?/sectors/:cragSectorId?/:cragSectorName?/edit",
    component: _537af2a2,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "crags-cragId-cragName-sectors-cragSectorId-cragSectorName-edit"
  }, {
    path: "/crags/:cragId?/:cragName?/routes/:cragRouteId/:cragRouteName?/ascents/new",
    component: _24520a87,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter à mon carnet"},"en":{"metaTitle":"Add to my logbook"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-routes-cragRouteId-cragRouteName-ascents-new"
  }, {
    path: "/crags/:cragId?/:cragName?/sectors/:cragSectorId?/:cragSectorName?/routes/new",
    component: _68641d73,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter une ligne"},"en":{"metaTitle":"Add crag route"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-sectors-cragSectorId-cragSectorName-routes-new"
  }, {
    path: "/crags/:cragId?/:cragName?/routes/:cragRouteId/:cragRouteName?/ascents/:ascentCragRouteId?/edit",
    component: _6c6d676a,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier ma croix"},"en":{"metaTitle":"Edit my ascent"}}},"orphanRoute":true},
    name: "crags-cragId-cragName-routes-cragRouteId-cragRouteName-ascents-ascentCragRouteId-edit"
  }, {
    path: "/guide-book-papers/:guideBookPaperId?/:guideBookPaperName?/add-crag",
    component: _abf2f89a,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un site à %{name}"},"en":{"metaTitle":"Add crag in %{name}"}}},"orphanRoute":true},
    name: "guide-book-papers-guideBookPaperId-guideBookPaperName-add-crag"
  }, {
    path: "/guide-book-papers/:guideBookPaperId?/:guideBookPaperName?/cover",
    component: _4b5a5df5,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Changer la couverture de %{name}"},"en":{"metaTitle":"Change cover of %{name}"}}},"orphanRoute":true},
    name: "guide-book-papers-guideBookPaperId-guideBookPaperName-cover"
  }, {
    path: "/guide-book-papers/:guideBookPaperId?/:guideBookPaperName?/edit",
    component: _2c8003bc,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "guide-book-papers-guideBookPaperId-guideBookPaperName-edit"
  }, {
    path: "/guide-book-papers/:guideBookPaperId?/:guideBookPaperName?/place-of-sales/new",
    component: _7dfc7f5f,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un point de vente"},"en":{"metaTitle":"Add a place of sale"}}},"orphanRoute":true},
    name: "guide-book-papers-guideBookPaperId-guideBookPaperName-place-of-sales-new"
  }, {
    path: "/guide-book-papers/:guideBookPaperId?/:guideBookPaperName?/place-of-sales/:placeOfSaleId?/edit",
    component: _fc4d2312,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier le point de vente"},"en":{"metaTitle":"Edit place of sale"}}},"orphanRoute":true},
    name: "guide-book-papers-guideBookPaperId-guideBookPaperName-place-of-sales-placeOfSaleId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/administration-request",
    component: _28c8cb86,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Demande d'administration de %{name}","metaDescription":"Administrez la salle d'escalade de %{name} pour créer votre topo indoor et animer votre communauté"},"en":{"metaTitle":"Request for administration of %{name}","metaDescription":"Manage the %{name} climbing gym to create your indoor topo and animate your community"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-administration-request"
  }, {
    path: "/gyms/:gymId?/:gymName?/administrator-required",
    component: _5a671e85,
    meta: {"props":{"gym":{"required":true}},"i18n":{"messages":{"fr":{"metaTitle":"Administrateur requis"},"en":{"metaTitle":"Required administrator"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-administrator-required"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins",
    component: _6989736d,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"%{name} - Dashboard"},"en":{"metaTitle":"%{name} - Dashboard"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins"
  }, {
    path: "/gyms/:gymId?/:gymName?/banner",
    component: _f413aedc,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Changer le bandeau"},"en":{"metaTitle":"Edit banner"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-banner"
  }, {
    path: "/gyms/:gymId?/:gymName?/edit",
    component: _476253f0,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "gyms-gymId-gymName-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/logo",
    component: _60bef311,
    meta: {"middleware":["auth"],"i18n":{"messages":{"fr":{"metaTitle":"Changer le logo"},"en":{"metaTitle":"Edit logo"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-logo"
  }, {
    path: "/gyms/:gymId?/:gymName?/ranking",
    component: _1f3ea0c0,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Le classement de %{name}","metaDescription":"Voir le classement des grimpeurs et grimpeuses de la salle d'escalade %{name}"},"en":{"metaTitle":"%{name}'s ranking","metaDescription":"See the ranking of climbers in the climbing gym %{name}"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-ranking"
  }, {
    path: "/gyms/:gymId?/:gymName?/select-space",
    component: _52b2d75b,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Choisissez un espace"},"en":{"metaTitle":"Choose a space"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-select-space"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces",
    component: _095d9d56,
    meta: {"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/administrators",
    component: _36727104,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Les administrateurs"},"en":{"metaTitle":"Administrators"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-administrators"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/championships",
    component: _7627284c,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Les championnats"},"en":{"metaTitle":"Championships"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-championships"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/climbing-styles",
    component: _1d1afd80,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Style d'escalade"},"en":{"metaTitle":"Climbing styles"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-climbing-styles"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/comments",
    component: _bf53f328,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Les commentaires"},"en":{"metaTitle":"Comments"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-comments"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/contests",
    component: _c11226d2,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Les contests"},"en":{"metaTitle":"Contests"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-contests"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/first-difficulty-system",
    component: _4925097f,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Premier système de difficulté"},"en":{"metaTitle":"First difficulty system"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-first-difficulty-system"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/first-space",
    component: _20560c84,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Créer le premier espace de %{name}","metaDescription":"commencez à créer votre topo d'escalade indoor en créant le première espace de %{name}"},"en":{"metaTitle":"Create the first space for %{name}","metaDescription":"Start creating your indoor climbing map by creating the first %{name} space"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-first-space"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/grades",
    component: _6759e598,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Les systèmes de difficultés"},"en":{"metaTitle":"Difficulty systems"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-grades"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/indoor-subscriptions",
    component: _08e59ff4,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Mes abonnements"},"en":{"metaTitle":"My subscriptions"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-indoor-subscriptions"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/label-templates",
    component: _02a4cdbe,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Modèle d'étiquettes"},"en":{"metaTitle":"Label templates"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-label-templates"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/levels",
    component: _4f981367,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Couleurs et cotations"},"en":{"metaTitle":"Colors and grades"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-levels"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/openers",
    component: _825e2e0c,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Les ouvreurs"},"en":{"metaTitle":"Openers"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-openers"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/opening-sheets",
    component: _19e36cfe,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Les fiches d'ouvertures"},"en":{"metaTitle":"Opening sheets"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-opening-sheets"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/ranking-systems",
    component: _4469f36b,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"System de classement"},"en":{"metaTitle":"Ranking system"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-ranking-systems"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/tree-structures",
    component: _7786774a,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"La structure"},"en":{"metaTitle":"Structure"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-tree-structures"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/videos",
    component: _8795abe0,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Les vidéos"},"en":{"metaTitle":"Videos"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-videos"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/new",
    component: _40f4d224,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Créer un nouvel espace"},"en":{"metaTitle":"Create a new space"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/administrators/new",
    component: _4572664c,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un administrateur"},"en":{"metaTitle":"Add administrator"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-administrators-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/championships/new",
    component: _451ebacc,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Créer un championnat"},"en":{"metaTitle":"Create championship"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-championships-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/contests/new",
    component: _882a04b6,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Créer un contest"},"en":{"metaTitle":"Create contest"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-contests-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/grades/new",
    component: _00bebcfc,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Nouveau system de cotation"},"en":{"metaTitle":"New grade system"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-grades-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/label-templates/new",
    component: _439cfc8c,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Créer un modèle d'étiquette"},"en":{"metaTitle":"Create label template"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-label-templates-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/openers/new",
    component: _b5629270,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un ouvreur"},"en":{"metaTitle":"Add opener"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-openers-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/opening-sheets/new",
    component: _48a88d0f,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Créer une fiche d'ouverture"},"en":{"metaTitle":"Create opening sheet"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-opening-sheets-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/routes/statistics",
    component: _100a2c23,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"%{name} - Statistique"},"en":{"metaTitle":"%{name} - Statistic"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-routes-statistics"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/routes/tables",
    component: _311d7c65,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"%{name} - Gestion des ouvertures"},"en":{"metaTitle":"%{name} - Routes management"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-routes-tables"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/space-groups/new",
    component: _78c25dc1,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Créer un group"},"en":{"metaTitle":"Create group"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-space-groups-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/spaces/edit-three-d",
    component: _37034e2f,
    meta: {"middleware":["auth","gymAdmin"],"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-spaces-edit-three-d"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/three-d-assets/new",
    component: _b74cc33e,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Importer une décoration"},"en":{"metaTitle":"Import asset"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-three-d-assets-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/championships/:championshipId",
    component: _249dda8e,
    meta: {"middleware":["auth","gymAdmin"],"orphanRoute":true},
    children: [{
      path: "",
      component: _b96e2b08,
      meta: {"middleware":["auth","gymAdmin"],"props":{"championship":{"required":true}},"orphanRoute":true},
      name: "gyms-gymId-gymName-admins-championships-championshipId"
    }, {
      path: "results",
      component: _76bde1c0,
      meta: {"middleware":["auth","gymAdmin"],"props":{"championship":{"required":true}},"orphanRoute":true},
      name: "gyms-gymId-gymName-admins-championships-championshipId-results"
    }]
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/contests/:contestId",
    component: _44de185b,
    meta: {"middleware":["auth","gymAdmin"],"orphanRoute":true},
    children: [{
      path: "",
      component: _1d8fae9e,
      meta: {"middleware":["auth","gymAdmin"],"props":{"contest":{"required":true}},"orphanRoute":true},
      name: "gyms-gymId-gymName-admins-contests-contestId"
    }, {
      path: "banner",
      component: _2cffb610,
      meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Illustration du contest"},"en":{"metaTitle":"Contest picture"}}},"orphanRoute":true},
      name: "gyms-gymId-gymName-admins-contests-contestId-banner"
    }, {
      path: "edit",
      component: _4465ae24,
      meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Créer un contest"},"en":{"metaTitle":"Create contest"}}},"orphanRoute":true},
      name: "gyms-gymId-gymName-admins-contests-contestId-edit"
    }, {
      path: "participants",
      component: _6656eb84,
      meta: {"middleware":["auth","gymAdmin"],"props":{"contest":{"required":true}}},
      name: "gyms-gymId-gymName-admins-contests-contestId-participants"
    }, {
      path: "results",
      component: _6f715a7c,
      meta: {"middleware":["auth","gymAdmin"],"props":{"contest":{"required":true}}},
      name: "gyms-gymId-gymName-admins-contests-contestId-results"
    }, {
      path: "time-line",
      component: _a642bc80,
      meta: {"middleware":["auth","gymAdmin"],"props":{"contest":{"required":true},"editContestModal":{"default":null}},"orphanRoute":true},
      name: "gyms-gymId-gymName-admins-contests-contestId-time-line"
    }]
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/grades/:gymGradeId",
    component: _51afba1b,
    meta: {"middleware":["auth","gymAdmin"],"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-grades-gymGradeId"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/label-templates/:gymLabelTemplateId",
    component: _1c38a4dc,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Étiquette : %{name}"},"en":{"metaTitle":"Label : %{name}"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-label-templates-gymLabelTemplateId"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/opening-sheets/:gymOpeningSheetId",
    component: _17c9dba2,
    meta: {"middleware":["auth","gymAdmin"],"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-opening-sheets-gymOpeningSheetId"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/administrators/:gymAdministratorId/edit",
    component: _3b75ac91,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier le system de cotation"},"en":{"metaTitle":"Edit grade system"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-administrators-gymAdministratorId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/grades/:gymGradeId/edit",
    component: _a4218c5e,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier le system de cotation"},"en":{"metaTitle":"Edit grade system"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-grades-gymGradeId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/label-templates/:gymLabelTemplateId?/print",
    component: _3d826d92,
    meta: {"layout":"print","orphanRoute":true},
    name: "gyms-gymId-gymName-admins-label-templates-gymLabelTemplateId-print"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/openers/:gymOpenerId/edit",
    component: _40e19bda,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier %{name}"},"en":{"metaTitle":"Edit %{name}"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-openers-gymOpenerId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/opening-sheets/:gymOpeningSheetId?/print",
    component: _46600006,
    meta: {"layout":"print","orphanRoute":true},
    name: "gyms-gymId-gymName-admins-opening-sheets-gymOpeningSheetId-print"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/space-groups/:gymSpaceGroupId?/edit",
    component: _253878ea,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier %{name}"},"en":{"metaTitle":"Edit %{name}"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-space-groups-gymSpaceGroupId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/spaces/:gymSpaceId?/edit-three-d",
    component: _9cb3094e,
    meta: {"middleware":["auth","gymAdmin"],"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-spaces-gymSpaceId-edit-three-d"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/spaces/:gymSpaceId?/take-three-d-picture",
    component: _34c59007,
    meta: {"middleware":["auth","gymAdmin"],"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-spaces-gymSpaceId-take-three-d-picture"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/three-d-assets/:threeDAssetId?/edit",
    component: _5faabad4,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Éditer la décoration"},"en":{"metaTitle":"Edit asset"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-three-d-assets-threeDAssetId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/grades/:gymGradeId/grade-lines/new",
    component: _41bc4423,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Nouveau niveau"},"en":{"metaTitle":"New level"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-grades-gymGradeId-grade-lines-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/admins/grades/:gymGradeId/grade-lines/:gymGradeLineId?/edit",
    component: _540def3c,
    meta: {"middleware":["auth","gymAdmin"],"i18n":{"messages":{"fr":{"metaTitle":"Modifier le niveau"},"en":{"metaTitle":"Edit level"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-admins-grades-gymGradeId-grade-lines-gymGradeLineId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/contests/rankers/:contestId?/:contestName",
    component: _486493c3,
    meta: {"layout":"contest","channels":{"ContestRankersChannel":{}},"orphanRoute":true},
    name: "gyms-gymId-gymName-contests-rankers-contestId-contestName"
  }, {
    path: "/gyms/:gymId?/:gymName?/championships/:championshipId?/:championshipName",
    component: _97f1a1ea,
    meta: {"layout":"contest","orphanRoute":true},
    name: "gyms-gymId-gymName-championships-championshipId-championshipName"
  }, {
    path: "/gyms/:gymId?/:gymName?/contests/:contestId?/:contestName?",
    component: _a41543d6,
    meta: {"layout":"contest","orphanRoute":true},
    children: [{
      path: "",
      component: _d2b37e50,
      meta: {"layout":"contest","props":{"contest":{"required":true},"updateToken":{"required":true}},"orphanRoute":true},
      name: "gyms-gymId-gymName-contests-contestId-contestName"
    }, {
      path: "my-contest",
      component: _8e8a1d46,
      meta: {"props":{"contest":{"required":true}}},
      name: "gyms-gymId-gymName-contests-contestId-contestName-my-contest"
    }, {
      path: "results",
      component: _56023e7c,
      meta: {"props":{"contest":{"required":true}}},
      name: "gyms-gymId-gymName-contests-contestId-contestName-results"
    }]
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName",
    component: _076e13f2,
    meta: {"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/edit",
    component: _0ea2c91a,
    meta: {"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/select-sector",
    component: _11f151f7,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Choisissez un secteur"},"en":{"metaTitle":"Choose a sector"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-select-sector"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/upload-3d",
    component: _3a25bf7d,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Modifier le 3D"},"en":{"metaTitle":"Modify the 3D"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-upload-3d"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/upload-plan",
    component: _0603d315,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Modifier le plan de fond"},"en":{"metaTitle":"Modify the background schema"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-upload-plan"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/sectors/new",
    component: _7dd0ed7e,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ajouter un secteur"},"en":{"metaTitle":"Add a sector"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-sectors-new"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/routes/:gymRouteId?/edit",
    component: _2775c656,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Modifier la ligne"},"en":{"metaTitle":"Edit route"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-routes-gymRouteId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/routes/:gymRouteId?/picture",
    component: _584a3962,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Définir une photo"},"en":{"metaTitle":"Define the picture"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-routes-gymRouteId-picture"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/routes/:gymRouteId?/thumbnail",
    component: _4a44f960,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Définir une miniature"},"en":{"metaTitle":"Define thumbnail"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-routes-gymRouteId-thumbnail"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/sectors/:gymSectorId?/edit",
    component: _50f587fa,
    meta: {"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-sectors-gymSectorId-edit"
  }, {
    path: "/gyms/:gymId?/:gymName?/spaces/:gymSpaceId/:gymSpaceName?/sectors/:gymSectorId?/routes/new",
    component: _11ff50cb,
    meta: {"i18n":{"messages":{"fr":{"metaTitle":"Ajouter une ligne"},"en":{"metaTitle":"Add a new route"}}},"orphanRoute":true},
    name: "gyms-gymId-gymName-spaces-gymSpaceId-gymSpaceName-sectors-gymSectorId-routes-new"
  }, {
    path: "/organizations/:organizationId?/:organizationName?/edit",
    component: _27ef3dfe,
    meta: {"middleware":["auth"],"orphanRoute":true},
    name: "organizations-organizationId-organizationName-edit"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
